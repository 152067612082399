/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useSelector, useDispatch } from "react-redux";
import { BudgetItem, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, TransactionItem, UserItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid } from "@mui/material";
import { FileEarmarkSpreadsheetFill } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import ExpensesReport from "../components/Modals/ExpensesReport";
import UserReport from "../components/Modals/UserReport";
import { useAuth } from "../features/Fetch/Auth";
import { useEffect } from "react";
import { pageLoad } from "../App";
import { Features } from "../constants";

const Reports = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();
	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = repo.GetBudgetTree();
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	if (!FamilyHasAccess(user, Features.Reports)) {
		navigate("/");
		return <></>;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={8} >
					<h2 className="mt-2 text-app">Reports</h2>
				</Grid>
				{(transactions == null || categories == null) && (
					<p><em>Loading...</em></p>
				)}
			</Grid>
			{(transactions != null && categories != null) && (
				<>
					<Grid container rowSpacing={3} columnSpacing={2} className='p-1 m-0 w-100'>
						{user.isHoH && (<>
							<Grid item xs={12}>
								<h3>By Budget</h3>
							</Grid>
							<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenOverallReport(false)}>
								<FileEarmarkSpreadsheetFill /> Spending
							</Grid >
							<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenOverallReport(true)} >
								<FileEarmarkSpreadsheetFill /> Mileage
							</Grid >
							<Grid item xs={12}>
								<h3>By User</h3>
							</Grid>
							<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenUsersReport(false)} >
								<FileEarmarkSpreadsheetFill /> Spending
							</Grid >
							<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenUsersReport(true)} >
								<FileEarmarkSpreadsheetFill /> Mileage
							</Grid >
						</>)}
						<Grid item xs={12}>
							<h3>My Activity</h3>
						</Grid>
						<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenMyReport(false)} >
							<FileEarmarkSpreadsheetFill /> Spending
						</Grid >
						<Grid item xs={6} className="btn btn-list btn-report text-app border border-app" onClick={() => OpenMyReport(true)} >
							<FileEarmarkSpreadsheetFill /> Mileage
						</Grid >
					</Grid>
				</>
			)}
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function OpenOverallReport(isMileage: boolean) {
		modal = {
			...modal,
			Body: <ExpensesReport IsMileage={isMileage} Budgets={budgets} Categories={categories} Transactions={transactions} User={user} StartDate={new DateTime().addDays(-30)} EndDate={new DateTime()} ViewReport={ViewBudgetReport} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenUsersReport(isMileage: boolean) {
		modal = {
			...modal,
			Body: <UserReport IsMileage={isMileage} Users={users} Budgets={budgets} Categories={categories} Transactions={transactions} User={user} StartDate={new DateTime().addDays(-30)} EndDate={new DateTime()} ViewReport={ViewUserReport} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenMyReport(isMileage: boolean) {
		modal = {
			...modal,
			Body: <UserReport IsMileage={isMileage} Users={users.filter(u => u.isMe)} Budgets={budgets} Categories={categories} Transactions={transactions} User={user} StartDate={new DateTime().addDays(-30)} EndDate={new DateTime()} ViewReport={ViewUserReport} IsMe={true} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function ViewBudgetReport() {
		HideModal();
		navigate("/report/budgets");
	}

	function ViewUserReport() {
		HideModal();
		navigate("/report/users");
	}
}

export default Reports;