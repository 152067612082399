/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Amount from "../Inputs/Amount";
import Date from "../Inputs/Date";
import { AccountItem, BudgetItem, DateTime, RecurringItem, SelectableItem, TransactionItem } from "../../types";
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { ChevronDown } from "react-bootstrap-icons";
import EditTransaction from "./EditTransaction";
import Toggle from "../Inputs/Toggle";
import ModalHeader from "./ModalHeader";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";

const EditRecurring = (props: any) => {
	let [transaction, setTransaction] = useState(props.Transaction as RecurringItem);
	let budgets: BudgetItem[] = props.Budgets;
	let accounts: AccountItem[] = props.Accounts;

	let timePeriods: SelectableItem[] = [
		{ Id: 1, Value: 'Weekly' },
		{ Id: 2, Value: 'Bi-Weekly' },
		{ Id: 3, Value: 'Monthly' },
		{ Id: 4, Value: '3 Months' },
		{ Id: 5, Value: '6 Months' },
		{ Id: 6, Value: 'Yearly' },
	];

	return (<>
		{!props.Nested &&
			<ModalHeader Title={`${transaction.recurringTransactionId > 0 ? 'Edit' : 'Add'} Repeating`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(transaction)} />
		}
		<div className={props.Nested ? "w-100" : "modal-body mt-5"}>
			<Grid container columnSpacing={3}>
				{(transaction.nextDate.diff(new DateTime()) < 0) && <>
					<Grid item xs={3} className="centered mb-5">
					</Grid>
					<Grid item xs={6} style={{ padding: 0 }} className="centered mb-5 highlighted btn px-0">
						<Button className="w-100 mx-0 my-0" variant="contained" onClick={() => UpdateIsIncome(false)}>Mark As Paid</Button>
					</Grid>
					<Grid item xs={3} className="centered mb-5">
					</Grid>
				</>}
				{props.Nested || transaction.recurringTransactionId > 0 ? <></> :
					<Grid item xs={12} className="centered mb-5">
						<ButtonGroup>
							<Button variant={transaction.isIncome ? "outlined" : "contained"} onClick={() => UpdateIsIncome(false)}>Expense</Button>
							<Button variant={transaction.isIncome ? "contained" : "outlined"} onClick={() => UpdateIsIncome(true)}>Income</Button>
						</ButtonGroup>
					</Grid>
				}
				{!props.Nested && <>
					<Amount Label="Expected Amount" Size={6} Value={transaction.nextAmount} OnChange={(val: any) => UpdateAmount(val.target.value)} />
					<Date Size={6} Label={`Next ${transaction.isIncome ? 'Payday' : 'Payment'}`} Value={transaction.nextDate} OnChange={UpdateDate} />
					<Description Label={transaction.isIncome ? "Payed By..." : "Payed To..."} Value={transaction.description} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
				</>}
				<Dropdown Size={12}
					Class="mb-0"
					Label="Timeframe"
					Options={timePeriods}
					Index={0}
					Value={TimePeriodIdFromMonthsDays()}
					OnChange={UpdateTimePeriod} />
				<Grid item xs={12}>
					{!props.Nested &&
						<Accordion className="w-100">
							<AccordionSummary expandIcon={<ChevronDown />}>
								<Typography>AutoBudget Options</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container columnSpacing={3}>
									<Dropdown Size={12}
										Class="mb-0"
										Label="Account"
										Options={GetAccountDict()}
										Index={0}
										Value={transaction.accountId}
										OnChange={UpdateAccount} />
									<Dropdown Size={6}
										Class="mb-0"
										Label="Budget"
										Options={GetBudgetDict()}
										Value={transaction.budgetId}
										OnChange={UpdateBudget} />
									{transaction.categoryId === 0 ?
										<Description Label="Category" Value={transaction.categoryName} OnChange={(val: any) => UpdateCategoryName(val.target.value)} Size={6} /> :
										<Dropdown Size={6}
											Class="mb-0"
											Label="Category"
											Options={GetCategoryDict()}
											Value={transaction.categoryId}
											OnChange={UpdateCategory} />
									}
								</Grid>
								<Grid item xs={12}>
									<Accordion className="w-100">
										<AccordionSummary expandIcon={<ChevronDown />}>
											<Typography>Advanced</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Toggle Size={12} OnChange={UpdateIsVariable} Checked={transaction.isVariable ?? false} Label="Amount is Variable" className="mb-3" />
											<Grid container columnSpacing={3} visibility={transaction.isVariable ? "visible" : "hidden"}>
												<Amount Label="Min Amount" Size={6} Value={transaction.minAmount} OnChange={(val: any) => UpdateMinAmount(val.target.value)} />
												<Amount Label="Max Amount" Size={6} Value={transaction.maxAmount} OnChange={(val: any) => UpdateMaxAmount(val.target.value)} />
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</AccordionDetails>
						</Accordion>
					}
				</Grid>
				<Grid item xs={12}>
					{transaction.recurringTransactionId > 0 && transaction.previousTransaction &&
						<Accordion className="w-100">
							<AccordionSummary expandIcon={<ChevronDown />}>
								<Typography>Edit Most Recent Transaction</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<EditTransaction Nested Transaction={transaction.previousTransaction} Budgets={budgets} OnChange={(t: TransactionItem) => UpdateTransaction(t)} />
							</AccordionDetails>
						</Accordion>
					}
				</Grid>
			</Grid>
		</div>
		{!props.Nested &&
			<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(transaction)} Close={props.Close} />
		}
	</>);

	function GetAccountDict() {
		let result: SelectableItem[] = [];
		accounts.forEach(b => result.push({
			Id: b.accountId,
			Value: b.description
		}));
		return result;
	}
	function GetBudgetDict() {
		let result: SelectableItem[] = [];
		budgets.forEach(b => result.push({
			Id: b.budgetId,
			Value: b.description
		}));
		return result;
	}
	function GetCategoryDict() {
		let result: SelectableItem[] = [];
		budgets
			.find(b => b.budgetId === transaction.budgetId)
			?.categories
			?.forEach(b => result.push({
				Id: b.categoryId,
				Value: b.description
		}));
		result.push({
			Id: 0,
			Value: "+ Add Category"
		});
		return result;
	}
	function ShouldShowSave() {
		if (!transaction.description) return false;
		return true;
	}
	function UpdateDescription(newValue: string) {
		let item = ({ ...transaction, description: newValue });
		setTransaction(item);
	}
	function MonthsDaysFromTimePeriodId(id: number) {
		switch (id) {
			case 1:
				return [0, 7];
			case 2:
				return [0, 14];
			case 3:
				return [1, 0];
			case 4:
				return [3, 0];
			case 5:
				return [6, 0];
			case 6:
				return [12, 0];
		}
		return [0, 0];
	}
	function TimePeriodIdFromMonthsDays() {
		if (transaction.months > 11) {
			return 6;
		}
		if (transaction.months > 5) {
			return 5;
		}
		if (transaction.months > 1) {
			return 4;
		}
		if (transaction.months > 0) {
			return 3;
		}
		if (transaction.days == 7) {
			return 1;
		}
		if (transaction.days == 14) {
			return 2;
		}
		return 0;
	}
	function UpdateTimePeriod(newValue: number) {
		let monthday = MonthsDaysFromTimePeriodId(newValue);
		let item = ({ ...transaction, months: monthday[0], days: monthday[1] });
		setTransaction(item);
		if (props.Nested) {
			props.OnChange(item);
		}
	}
	function UpdateAccount(newValue: any) {
		let item = ({ ...transaction, accountId: newValue });
		setTransaction(item);
	}
	function UpdateBudget(newValue: any) {
		let item = ({ ...transaction, budgetId: newValue });
		setTransaction(item);
	}
	function UpdateCategory(newValue: any) {
		let item = ({ ...transaction, categoryId: newValue });
		setTransaction(item);
	}
	function UpdateCategoryName(newValue: string) {
		let item = ({ ...transaction, categoryId: 0, categoryName: newValue });
		setTransaction(item);
	}
	function UpdateDate(newValue: any) {
		let item = ({ ...transaction, nextDate: new DateTime(newValue) });
		setTransaction(item);
	}
	function UpdateAmount(newValue: string) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
		}
		let item = { ...transaction, nextAmount: val };
		setTransaction(item);
	}
	function UpdateMinAmount(newValue: string) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
		}
		let item = { ...transaction, minAmount: val };
		setTransaction(item);
	}
	function UpdateMaxAmount(newValue: string) {
		let val = 0;
		if (newValue) {
			val = parseFloat(newValue);
		}
		let item = { ...transaction, maxAmount: val };
		setTransaction(item);
	}
	function UpdateIsVariable(event: any, newValue: boolean) {
		let item = ({ ...transaction, isVariable: newValue });
		setTransaction(item);
	}
	function UpdateIsIncome(newValue: boolean) {
		let item = { ...transaction, isIncome: newValue };
		setTransaction(item);
	}
	function UpdateTransaction(newValue: TransactionItem) {
		let item = { ...transaction, previousTransaction: newValue };
		setTransaction(item);
	}

	function MarkPaid() {

	}
}


export default EditRecurring;