import { useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Amount from "../Inputs/Amount";
import Date from "../Inputs/Date";
import { BudgetItem, CategoryItem, ColorHexes, CurrentUser, FamilyFeatureValue, FamilyHasAccess, SelectableItem, TimeframeItem, UserItem } from "../../types";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { Circle } from "@uiw/react-color";
import { ChevronDown } from "react-bootstrap-icons";
import Toggle from "../Inputs/Toggle";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import { Features } from "../../constants";
import AutoCompleteCategories from "../Inputs/AutoCompleteCategories";

const EditBudget = (props: any) => {
	let [budget, setBudget] = useState(props.Item as BudgetItem);
	let categories: CategoryItem[] = props.Categories;
	let timeframes: TimeframeItem[] = props.Timeframes;
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	return (<>
		<ModalHeader Title={`${budget.budgetId > 0 ? 'Edit' : 'Add'} Budget`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(budget)} />
		<div className="modal-body mt-5">
			<Grid container>
				<Description Label="Title" Value={budget.description} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
				<Amount Size={12} Value={budget.variant.amount} OnChange={(val: any) => UpdateAmount(val.target.value)} />
				<Dropdown Size={12}
					Class="mb-0"
					Label="Timeframe"
					Options={timeframes
						.map(t => {
							return {
								Id: t.timeframeId,
								Value: t.description
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.timeframeId}
					OnChange={UpdateTimeframe} />
				<AutoCompleteCategories Size={12}
					Class="mb-0"
					Options={categories
						.map(c => {
							return {
								Id: c.categoryId,
								Value: c.description
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.categoryIds}
					Multiple
					OnChange={UpdateCategories} />
				<Dropdown Size={12}
					Class="mb-0"
					Label="Assigned Users"
					Options={users
						.map(u => {
							return {
								Id: u.userAccountId,
								Value: `${u.firstName} ${u.lastName}`
							} as SelectableItem;
						})}
					Index={0}
					Value={budget.userIds}
					Multiple
					OnChange={UpdateUsers} />
				<Accordion className="w-100">
					<AccordionSummary expandIcon={<ChevronDown />}>
						<Typography>Advanced</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid item xs={12}>
							Color:
							<Circle color={budget.color} colors={ColorHexes} onChange={UpdateColor} />
						</Grid>
						{budget.budgetId > 0 && <>
							<Date Size={12} Label="Budget Period Custom End Date (Optional)" Value={budget.variant.endDate} OnChange={UpdateDate} NoError />
							<Toggle Size={12} Label="Apply updated amount to future budgets" OnChange={UpdateIsDefault} Checked={budget.variant.isDefault ?? false} className="my-3" />
						</>}
						<Toggle Size={12} Label="Do not require approval from unassigned users" OnChange={UpdateRequiresApproval} Checked={!budget.requiresApproval} className="my-3" />
						<Toggle Size={12} Label={`Exclude items in this budget from ${FamilyFeatureValue(user, Features.TransactionTitle)} page`} OnChange={UpdateIsViewTransactions} Checked={!budget.isViewTransactions} className="my-3" />
						{FamilyHasAccess(user, Features.Income) &&
							<Toggle Size={12} Label="Use Budget for Income Only" OnChange={UpdateIsIncome} Checked={budget.isIncome} className="my-3" />
						}
					</AccordionDetails>
				</Accordion>
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(budget)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		if (!budget.description) return false;
		return true;
	}

	function UpdateDescription(newValue: string) {
		var item = ({ ...budget, description: newValue });
		setBudget(item);
	}
	function UpdateAmount(newValue: string) {
		let val: number = 0;
		if (newValue) val = parseFloat(newValue);
		let variant = { ...budget.variant, amount: val };
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
	function UpdateTimeframe(newValue: any) {
		let item = ({ ...budget, timeframeId: newValue });
		setBudget(item);
	}
	function UpdateCategories(newValue: any) {
		let item = ({ ...budget, categoryIds: newValue });
		setBudget(item);
	}
	function UpdateUsers(newValue: any) {
		let item = ({ ...budget, userIds: newValue });
		setBudget(item);
	}
	function UpdateColor(newValue: any) {
		let item = ({ ...budget, color: newValue.hex });
		setBudget(item);
	}
	function UpdateIsDefault(event: any, newValue: boolean) {
		let variant = { ...budget.variant, isDefault: newValue };
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
	function UpdateRequiresApproval(event: any, newValue: boolean) {
		let item = ({ ...budget, requiresApproval: !newValue });
		setBudget(item);
	}
	function UpdateIsViewTransactions(event: any, newValue: boolean) {
		let item = ({ ...budget, isViewTransactions: !newValue });
		setBudget(item);
	}
	function UpdateIsIncome(event: any, newValue: boolean) {
		let item = ({ ...budget, isIncome: newValue });
		setBudget(item);
	}
	function UpdateDate(newValue: any) {
		let variant = { ...budget.variant, endDate: newValue }
		let item = ({ ...budget, variant: { ...variant } });
		setBudget(item);
	}
}

export default EditBudget;