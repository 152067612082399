import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../features/Fetch/Auth";
import { CheckEnterKey } from "../../pages/Security/Login";
import Error from "../Error";
import Divider from "../Inputs/Divider";
import EmailInput from "../Inputs/EmailInput";

let initialStateVars = {
	Email: '',
	Password: ''
};

interface ForgotProps {
	Logo: string,
	LoginClicked: () => void
}
const Forgot = (props: ForgotProps) => {
	let auth = useAuth();

	let [stateVars, setStateVars] = useState(initialStateVars);
	let [error, setError] = useState('');
	let [overlay, setOverlay] = useState(false);

	return (
		<>
			<div className="centered mt-20 text-success">
				<h5>Forgot Password for</h5>
			</div>
			<img className="img-title" src={props.Logo} alt="Shekl" />
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<Error Text={error} />
				</Grid>
				<EmailInput Required Id="inputEmail" Label="Email" Value={stateVars.Email} Size={8} OnChange={emailChange} OnKeyUp={(e: string) => CheckEnterKey(e, forgotPassword)} />
				<Grid item xs={8}>
					<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={forgotPassword}>Submit</Button>
				</Grid>
				<Divider />
			</Grid>
			<Button variant="text" className="centered" onClick={props.LoginClicked}>Back to Login</Button>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
				<CircularProgress />
			</Backdrop>
		</>
    );

	function emailChange(input: any) {
		setStateVars({
			...stateVars, Email: input.target.value
		});
	}

	async function forgotPassword() {
		setOverlay(true);
		if (String(stateVars.Email).match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			await auth.forgotPassword(stateVars.Email);
			setError("If the supplied email was tied to a valid Shekl account, an email will be sent with password reset instructions.");
			setOverlay(false);
		} else {
			setOverlay(false);
			setError('You must enter a valid email.');
		}
	}
};

export default Forgot;