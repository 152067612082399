import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, ModalProps } from "@mui/material";
import { useState } from "react";
import { ArrowLeftCircle, Trash3Fill, XLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

const ModalHeader = (props: any) => {
	let isMobile: ModalProps = useSelector((state: any) => state.isMobile.value);
	let [confirm, setConfirm] = useState(false);

	return (<>
		<Grid container className="mt-3 mb-3 modal-header">
			<Grid item xs={2} >
				{isMobile ? (
					<IconButton
						className="cursor-pointer"
						aria-label='close'
						onClick={props.Close}>
						<ArrowLeftCircle />
					</IconButton>
				) : (
					<IconButton
						className="cursor-pointer"
						aria-label='close'
							onClick={props.Close}>
							<XLg />
					</IconButton>
				)}
			</Grid>
			<Grid item xs={8} >
				<h1 className="centered text-app">
					{props.Title}
				</h1>
			</Grid>
			<Grid item xs={2}>
				{props.CanDelete && (
					<IconButton
						className="cursor-pointer text-danger"
						aria-label='delete'
						onClick={Confirm}>
						<Trash3Fill />
					</IconButton>
				)}
			</Grid>
		</Grid>

		<Dialog open={confirm} onClose={CloseConfirm}>
			<DialogContent className="px-5 mt-3">
				<DialogContentText>
					Are you sure?
				</DialogContentText>
			</DialogContent>
			<DialogActions className="px-3">
				<Button className="mx-3 my-4" onClick={CloseConfirm} variant="outlined">Cancel</Button>
				<Button onClick={ConfirmDelete} variant="contained" className="bg-danger mx-3">Delete</Button>
			</DialogActions>
		</Dialog>
	</>);

	function Confirm() {
		setConfirm(true);
	}

	function CloseConfirm() {
		setConfirm(false);
	}

	function ConfirmDelete() {
		setConfirm(false);
		props.Delete();
	}
}

export default ModalHeader;