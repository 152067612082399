import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../features/Fetch/Auth";
import { CheckEnterKey } from "../../pages/Security/Login";
import Error from "../Error";
import Divider from "../Inputs/Divider";
import EmailInput from "../Inputs/EmailInput";
import PasswordInput from "../Inputs/PasswordInput";
import TextInput from "../Inputs/TextInput";

let initialStateVars = {
	Email: '',
	FirstName: '',
	LastName: '',
	Code: '',
	Password: '',
	ConfirmPassword: ''
};

interface RegisterProps {
	Logo: string,
	LoginClicked: () => void,
	Verify: (e: string) => void
}
const Register = (props: RegisterProps) => {
	let auth = useAuth();

	let [stateVars, setStateVars] = useState(initialStateVars);
	let [error, setError] = useState('');
	let [overlay, setOverlay] = useState(false);
	let [hasLength, setHasLength] = useState(false);
	let [hasLower, setHasLower] = useState(false);
	let [hasUpper, setHasUpper] = useState(false);
	let [hasNumber, setHasNumber] = useState(false);
	let [hasSpecial, setHasSpecial] = useState(false);
	let isValidPassword = hasLength && hasLower && hasUpper && hasNumber && hasSpecial;

	return (
		<>
			<div className="centered mt-20 text-success">
				<h5>Register for</h5>
			</div>
			<img className="img-title" src={props.Logo} alt="Shekl" />
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<Error Text={error} />
				</Grid>
				<TextInput Required Id="inputFirst" Label="First Name" Value={stateVars.FirstName} Size={8} OnChange={(e: string) => firstNameChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<TextInput Required Id="inputLast" Label="Last Name" Value={stateVars.LastName} Size={8} OnChange={(e: string) => lastNameChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<TextInput Required Id="inputCode" Label="Family Code" Value={stateVars.Code} Size={8} OnChange={(e: string) => codeChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<EmailInput Required Id="inputEmail" Label="Email" Value={stateVars.Email} Size={8} OnChange={(e: string) => emailChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<Grid item xs={7} textAlign="left">
					<div>Password must have the following:</div>
					<div style={{ color: hasLength ? "green" : "orange" }}>8 or more characters</div>
					<div style={{ color: hasLower ? "green" : "orange" }}>Lowercare letter</div>
					<div style={{ color: hasUpper ? "green" : "orange" }}>Capital letter</div>
					<div style={{ color: hasNumber ? "green" : "orange" }}>Number</div>
					<div style={{ color: hasSpecial ? "green" : "orange" }}>Special character</div>
				</Grid>
				<PasswordInput Id="inputPassword" Label="Password" Value={stateVars.Password} Size={8} OnSubmit="@OnSubmit" OnChange={(e: string) => passwordChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<PasswordInput Id="confirmPassword" Label="Confirm Password" Value={stateVars.ConfirmPassword} Size={8} OnSubmit="@OnSubmit" OnChange={(e: string) => confirmPasswordChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, register)} />
				<Grid item xs={8}>
					<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={register}>Register</Button>
				</Grid>
				<Divider Text="OR" />
			</Grid>
			<h6 className="mt-3">Already have an acocunt?</h6>
			<Button variant="text" className="centered" onClick={props.LoginClicked}>LOG IN</Button>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
				<CircularProgress />
			</Backdrop>
		</>
    );

	function emailChange(input: any) {
		setStateVars({
			...stateVars, Email: input.target.value
		});
	}

	function firstNameChange(input: any) {
		setStateVars({
			...stateVars, FirstName: input.target.value
		});
	}

	function lastNameChange(input: any) {
		setStateVars({
			...stateVars, LastName: input.target.value
		});
	}

	function codeChange(input: any) {
		setStateVars({
			...stateVars, Code: input.target.value
		});
	}

	function passwordChange(input: any) {
		let validationRegex = [
			{ regex: /.{8,}/ },
			{ regex: /[0-9]/ },
			{ regex: /[a-z]/ },
			{ regex: /[A-Z]/ },
			{ regex: /[^A-Za-z0-9]/ },
		];

		setHasLength(validationRegex[0].regex.test(input.target.value));
		setHasNumber(validationRegex[1].regex.test(input.target.value));
		setHasLower(validationRegex[2].regex.test(input.target.value));
		setHasUpper(validationRegex[3].regex.test(input.target.value));
		setHasSpecial(validationRegex[4].regex.test(input.target.value));

		setStateVars({
			...stateVars, Password: input.target.value
		});
	}

	function confirmPasswordChange(input: any) {
		setStateVars({
			...stateVars, ConfirmPassword: input.target.value
		});
	}

	async function register() {
		setOverlay(true);
		if (!stateVars.FirstName) {
			setOverlay(false);
			setError('You must enter a first name.');
		} else if (!stateVars.LastName) {
			setOverlay(false);
			setError('You must enter a last name.');
		} else if (!stateVars.Code || stateVars.Code.length !== 10) {
			setOverlay(false);
			setError('You must have an 10-digit family code supplied by a family manager.');
		} else if (!String(stateVars.Email).match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			setOverlay(false);
			setError('You must enter a valid email.');
		} else if (!stateVars.Password || !isValidPassword) {
			setOverlay(false);
			setError('Your password must fit the complexity requirements.');
		} else if (stateVars.Password != stateVars.ConfirmPassword) {
			setOverlay(false);
			setError('Your passwords must match.');
		} else {
			let result = await auth.register({ FirstName: stateVars.FirstName, LastName: stateVars.LastName, Code: stateVars.Code, Email: stateVars.Email, Password: stateVars.Password, PasswordConfirm: stateVars.ConfirmPassword });
			setError(result);
			setOverlay(false);
			if (result === 'Success') {
				props.Verify(stateVars.Email);
			}
		}
	}
};

export default Register;