import { useState } from "react";

import logo from '../../images/logo2.png'
import Login from "../../components/Security/Login";
import Register from "../../components/Security/Register";
import Forgot from "../../components/Security/Forgot";
import Verify from "../../components/Security/Verify";

const Authenticate = (props: any) => {
	let [step, setStep] = useState('login');
	let [email, setEmail] = useState('');

	function renderSwitch() {
		switch (step) {
			case 'login':
				return <Login Logo={logo} RegisterClicked={registerClicked} ForgotClicked={forgotClicked} Verify={gotoVerify} key="LoginPart" />;
			case 'register':
				return <Register Logo={logo} LoginClicked={loginClicked} Verify={gotoVerify} key="RegisterPart" />;
			case 'forgot':
				return <Forgot Logo={logo} LoginClicked={loginClicked} key="ForgotPart" />;
			case 'verify':
				return <Verify Logo={logo} LoginClicked={loginClicked} Email={email} key="VerifyPart" />;
			default:
				return <></>;
		}
	}

    return (
        <div className="login page">
			<div className="form-edit centered">
				{renderSwitch()}
			</div>
        </div>
    );

	function forgotClicked() {
		setStep("forgot");
	}

	function registerClicked() {
		setStep("register");
	}

	function loginClicked() {
		setStep("login");
	}

	function gotoVerify(email: string) {
		setEmail(email);
		setStep("verify");
	}
}

export function CheckEnterKey(e: any, promise: () => void) {
	if (e.key === "Enter") {
		promise();
	}
}


export default Authenticate;