export const Features = {
	Budgets: 1,
	Categories: 2,
	FamilyCodes: 3,
	Timeframes: 4,
	Transactions: 5,
	Users: 6,
	UserEmails: 7,
	Reports: 8,
	TransactionTitle: 9,
	Recurring: 10,
	Accounts: 12,
	Income: 13,
	CheckRequests: 14,
	Taxes: 15,
	ShowTransactionsAllUsers: 16
}

export const AccountTypeDict: {[index: number]: string} = {
	1: "Checking",
	2: "Savings",
	3: "Credit",
	4: "Cash"
}