import { useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Date from "../Inputs/Date";
import { DateTime, SelectableItem, TimeframeItem } from "../../types";
import { Grid } from "@mui/material";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditTimeframe = (props: any) => {
	let [timeframe, setTimeframe] = useState(props.Item as TimeframeItem);

	let timePeriods: SelectableItem[] = [
		{ Id: 1, Value: 'Weekly' },
		{ Id: 2, Value: 'Bi-Weekly' },
		{ Id: 3, Value: 'Monthly' },
		{ Id: 4, Value: 'Yearly' },
	];

	let endDate = GetPeriodEnd();

	return (<>
		<ModalHeader Title="Edit Timeframe" CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(timeframe)} />
		<div className="modal-body mt-5">
			<Grid container>
				<Description Label="Description" Value={timeframe.description} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
				<Dropdown Size={12}
					Class="mb-0"
					Label="Timeframe"
					Options={timePeriods}
					Index={0}
					Value={TimePeriodIdFromMonthsDays()}
					OnChange={UpdateTimePeriod} />
				<Date Size={12} Label="Period End Date" Value={endDate} OnChange={UpdateStartDate} />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(timeframe)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		return timeframe.description !== null;
	}

	function MonthsDaysFromTimePeriodId(id: number) {
		switch (id) {
			case 1:
				return [0, 7];
			case 2:
				return [0, 14];
			case 3:
				return [1, 0];
			case 4:
				return [12, 0];
		}
		return [0, 0];
	}

	function TimePeriodIdFromMonthsDays() {
		if (timeframe.months > 11) {
			return 4;
		}
		if (timeframe.months > 0) {
			return 3;
		}
		if (timeframe.days == 7) {
			return 1;
		}
		if (timeframe.days == 14) {
			return 2;
		}
		return 0;
	}

	function UpdateDescription(newValue: any) {
		timeframe = ({ ...timeframe, description: newValue });
		setTimeframe(timeframe);
	}
	function UpdateTimePeriod(newValue: number) {
		let monthday = MonthsDaysFromTimePeriodId(newValue);
		timeframe = ({ ...timeframe, months: monthday[0], days: monthday[1] });
		setTimeframe(timeframe);
	}
	function UpdateStartDate(newValue: any) {
		timeframe = ({ ...timeframe, startDate: new DateTime(newValue) });
		setTimeframe(timeframe);
	}

	function GetPeriodEnd() {
		let end = timeframe.startDate;
		let today = new DateTime().startOfDay();
		while (end < today) {
			end = end.addDays(timeframe.days).addMonths(timeframe.months);
		}
		return end;
	}
}

export default EditTimeframe