import { Grid, TextField } from "@mui/material";
import React from "react";

const Description = (props: any) => {
    return (
		<Grid item xs={props.Size}>
			<TextField
				label={(props.NoLabel ? "" : props.Label ?? "Description")}
				variant="outlined"
				className={`form-control border-app  mb-3 ${props.Class} col-${props.Size}`}
				required={props.Required}
				value={props.Value}
				onKeyUp={props.OnKeyUp}
				onChange={props.OnChange} />
		</Grid>
    );
};

export default Description;