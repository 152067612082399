import { Grid } from "@mui/material";
import React from "react";
import { StarFill, XCircle } from "react-bootstrap-icons";
import { UserEmailItem } from "../../types";

const UserEmail = (props: any) => {
    let item: UserEmailItem = props.Item;
	return (
		<Grid container className={`py-2 ${props.IsPrimary ? '' : 'text-app cursor-pointer'}`} style={{ textAlign: 'left' }} onClick={props.OnClick}>
			<Grid item xs={1} className="text-warning" style={{ textAlign: 'right', paddingRight: '10px' }} >
				{props.IsPrimary && (
					<StarFill />
				)}
			</Grid>
			<Grid item xs={11} xl={7} >
				<h3>{item.email}</h3>
			</Grid>
			<Grid item className="text-danger" style={{ textAlign: 'right' }} xs={12} xl={4} >
				{item.isVerified ? <></> : <>
					Not Verified
					<XCircle className="pb-1" />
				</>}
			</Grid>
		</Grid >
    );
}

export default UserEmail;