import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserItem } from "../../types";

interface UserState {
	value: UserItem[] | undefined
}

const initialState: UserState = {
	value: undefined
};

export const UserSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {
		set: (state = initialState, action: PayloadAction<UserItem[] | undefined>) => {
            state.value = action.payload;
        },
		add: (state = initialState, action: PayloadAction<UserItem>) => {
			let value: UserItem[] = state.value ?? [];
			state.value = [
                ...value, action.payload
            ];
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
			let value: UserItem[] = state.value ?? [];
			state.value = [
				...value.filter((item: UserItem) => {
					return item.userAccountId !== action.payload;
                })
            ];
		}
	}
});

export const { set, add, remove } = UserSlice.actions;

export default UserSlice.reducer;