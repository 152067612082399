import { Button } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../features/Fetch/Auth";
import { UserEmailItem } from "../../types";
import Description from "../Inputs/Description";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditUserEmail = (props: any) => {
	let auth = useAuth();

	let [userEmail, setUserEmail] = useState(props.Item as UserEmailItem);
	let [message, setMessage] = useState('');

	return (<>
		<ModalHeader Title={`${userEmail.userEmailId > 0 ? 'Edit' : 'Add'} Email`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(userEmail)} />
		<div className="modal-body mt-5">
			{props.Item.isDuplicate &&
				<div className="text-danger pb-3 px-3">
					{userEmail.email} has already been registered by you or another user.
				</div>
			}
			<Description Label="Email Address" Value={userEmail.email} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
			{userEmail.userEmailId > 0 && !userEmail.isVerified && (
				<h6 className="centered">
					<div className="col-8 centered mb-3">
						An email was sent to {userEmail.email} with a verification link. You will need to click the link in that email to verify your email address before you can log in.
					</div>
					<div className="col-8 centered">
						If you do not see the email, you may need to wait a little longer to receive it or check your Spam/Junk folder. If you have lost or deleted the email or feel it was never delivered, you may click the link below to send a new email.
					</div>
					<Button variant="text" className="centered" onClick={resendEmail}>Send Email Again</Button>
					<div className="col-8 centered">
						{message}
					</div>
				</h6>
			)}
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(userEmail)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		return userEmail.email !== null;
	}

	function UpdateDescription(newValue: any) {
		userEmail = ({ ...userEmail, email: newValue });
		setUserEmail(userEmail);
	}

	async function resendEmail() {
		var email = userEmail.email.replace('@', '%40');
		auth.resendEmailVerification(email);
		setMessage(`A new verification email has been sent to ${userEmail.email}.`);
	}
}

export default EditUserEmail