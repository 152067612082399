import { useState } from "react";

import { Button, Grid } from "@mui/material";
import { useAuth } from "../../features/Fetch/Auth";
import logo from '../../images/logo2.png'
import { useLocation, useNavigate } from "react-router-dom";

const RejectEmail = () => {
	let auth = useAuth();
	let location = useLocation();
	let navigate = useNavigate();
	let params = new URLSearchParams(location.search);

	let email = params.get('email');
	let token = params.get('token');

	auth.rejectEmail({ Code: token, Email: email })

    return (
        <div className="login page">
			<div className="form-edit centered">
				<img className="img-title" src={logo} alt="Shekl" />
				<div className="centered mt-20 text-success">
					<h5>Your email has been removed from our system.</h5>
				</div>
				<Grid container rowSpacing={3} justifyContent="center">
					<Grid item xs={8}>
						<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={() => navigate('/')}>Return to site</Button>
					</Grid>
				</Grid>
			</div>
        </div>
    );
}

export default RejectEmail;