import { Grid } from "@mui/material";
import { Wallet } from "react-bootstrap-icons";
import { DateTime, RecurringItem, USCurrency } from "../../types";

const Recurring = (props: any) => {
	let item: RecurringItem = props.Item;

	return (
		<Grid container className={`btn-list ${(item.nextDate.diff(new DateTime()) < 0 ? item.nextDate.diff(new DateTime().startOfDay()) < 0 ? 'text-danger' : 'text-warning' : 'text-app')}`} onClick={props.OnClick}>
			<Grid item xs={8} >
				<h3>{item.description}</h3>
			</Grid>
			<Grid item xs={4} className="right">
				{(item.nextDate.diff(new DateTime()) < 0 ? item.nextDate.diff(new DateTime().startOfDay()) < 0 ? 'Past Due' : 'Due Today' : 'Next Due Date')}
			</Grid>
			<Grid item xs={4} >
				{USCurrency(item.nextAmount)}
				{item.isIncome && (
					<Wallet className="text-success mx-2" />
				)}
			</Grid>
			<Grid item xs={8} className="right mb-1">
				{item.nextDate.toString()}
			</Grid>
		</Grid >
    );
}

export default Recurring;