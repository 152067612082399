import { Button, Grid } from "@mui/material";

const ModalFooterCustom = (props: any) => {

	return (
		<Grid container className="modal-footer">
			<Grid item xs={3}>
				{!props.HideSave &&
					<Button fullWidth variant="contained" onClick={props.Save} disabled={!props.ShowSave} className={`${props.ShowSave ? 'bg-success' : 'bg-disabled'} text-white btn-list`}>{props.SaveText ?? "Save"}</Button>
				}
			</Grid>
			<Grid item xs={1}></Grid>
			<Grid item xs={8}>
				<Button fullWidth variant="outlined" onClick={props.Close} className="border-success text-success btn-list">Cancel</Button>
			</Grid>
		</Grid>
	);
}

export default ModalFooterCustom;