import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryItem } from "../../types";

interface CategoryState {
	value: CategoryItem[] | undefined
}

const initialState: CategoryState = {
    value: []
};

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
	reducers: {
		set: (state = initialState, action: PayloadAction<CategoryItem[] | undefined>) => {
            state.value = action.payload;
        },
		add: (state = initialState, action: PayloadAction<CategoryItem>) => {
			let value: CategoryItem[] = state.value ?? [];
            state.value = [
                ...value, action.payload
			];
			return state;
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
			let value: CategoryItem[] = state.value ?? [];
			state.value = [
                ...value.filter((item: CategoryItem) => {
                    return item.categoryId !== action.payload;
                })
			];
			return state;
        }
    }
});

export const { set, add, remove } = categorySlice.actions;

export default categorySlice.reducer;