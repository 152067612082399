import { useDispatch, useSelector } from "react-redux";
import { connection } from "../../App";
import { CurrentUser } from "../../types";
import { remove } from "../Slices/userDataSlice";

export const useFetch = () => {
	const auth: CurrentUser = useSelector((state: any) => state.userData.value);
	let dispatch = useDispatch();
	function request(method: string, raw: boolean = false) {
		return (url: string, body?: any) => {
			let headers = authHeader();
			let options: any = { method: method, headers: headers };
			if (body) {
				headers.append("Access-Control-Allow-Origin", "*");
				headers.append("content-type", "application/json");
				options = {
					method: method,
					mode: 'cors',
					headers: headers,
					body: JSON.stringify(body)
				}
			}
			if (raw) {
				return fetch(`${connection}/${url}`, options);
			}

			return fetch(`${connection}/${url}`, options).then(handleResponse);
		};
	};

	function authHeader() {
		let token = auth?.token;
		let isLoggedIn = token && token !== '';
		let headers = new Headers();
		if (isLoggedIn) {
			headers.append("Authorization", `Bearer ${token}`);
		}
		return headers;
	};

	async function handleResponse(response: Response) {
		try {
			if (!response.ok) {
				if ([401, 403].includes(response.status) && auth?.token && auth.token !== '') {
					dispatch(remove());
					return Promise.reject("unauthorized");
				}
			}
			let res = await response.json().then(data => {
				if (!response.ok) {
					let error = (data && data.message) || response.statusText;
					return Promise.reject(error);
				}
				return data;
			});
			return res;
		} catch {
			return [];
		}
	}

	return {
		get: request('GET'),
		getRaw: request('GET', true),
		post: request('POST'),
		postRaw: request('POST', true),
		put: request('PUT'),
		delete: request('DELETE')
	};
}