import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PencilSquare } from "react-bootstrap-icons";
import { usePlaidLink } from "react-plaid-link";
import { useFetch } from "../../features/Fetch/Fetch";
import { useItems } from "../../features/Fetch/Items";

const UpdateLink = (props: any) => {
	let [token, setToken] = useState(null);

	let request = useFetch();
	let items = useItems();

	useEffect(() => {
		request.getRaw(`Settings/CreateLinkToken/true/${props.Account.accountId}`)
			.then(response => {
				response.text().then((data: any) => {
					setToken(data);
				});
			});
	}, []);

	const onSuccess = useCallback(async (publicToken: any, metadata: any) => {
		items.GetAccountsAsync();
	}, []);

	let isOauth = false;

	const config: any = {
		token,
		onSuccess,
	};

	const { open, ready } = usePlaidLink(config);

	useEffect(() => {
		if (isOauth && ready) {
			open();
		}
	}, [token, isOauth, ready, open]);

	return (
		<Grid item xs={6} md={2}>
			<div className="btn btn-account btn-list text-app" onClick={() => open()}>
				<h3>
					<PencilSquare /> Update
				</h3>
			</div>
		</Grid>
	);
}

export default UpdateLink;