import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, RecurringItem } from "../../types";

interface RecurringState {
	value: RecurringItem[]
}

const initialState: RecurringState = {
    value: []
};

export const recurringSlice = createSlice({
    name: 'recurring',
    initialState,
	reducers: {
		setRecurring: (state = initialState, action: PayloadAction<RecurringItem[]>) => {
			let value: RecurringItem[] = action?.payload ?? [];
			for (let i = 0; i < value.length; i++) {
				value[i] = { ...value[i], nextDate: new DateTime(value[i].nextDate) };
			}
			state.value = value;
		},
		addRecurring: (state = initialState, action: PayloadAction<RecurringItem>) => {
			let value: RecurringItem[] = state.value ?? [];
			let newItem = action.payload as RecurringItem;
			newItem = { ...newItem, nextDate: new DateTime(newItem.nextDate) };
			state.value = [
				...value, newItem
			];
			return state;
        },
		removeRecurring: (state = initialState, action: PayloadAction<number>) => {
			let value: RecurringItem[] = state.value ?? [];
			state.value = [
				...value.filter((item: RecurringItem) => {
					return item.recurringTransactionId !== action.payload;
				})
			];
			return state;
        },
    }
});

export const { setRecurring, addRecurring, removeRecurring } = recurringSlice.actions;

export default recurringSlice.reducer;