import React from "react";
import { Grid, Switch } from "@mui/material";

const Toggle = (props: any) => {
	return (
		<Grid item xs={props.Size} className={props.className}>
			{props.Label}
			<Switch onChange={props.OnChange} defaultChecked={props.Checked} className="right" disabled={props.Disabled} />
		</Grid>
    );
};

export default Toggle;