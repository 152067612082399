import { Grid } from "@mui/material";
import { XSquare } from "react-bootstrap-icons";
import { useFetch } from "../../features/Fetch/Fetch";

const RemoveLink = (props: any) => {

	let request = useFetch();

	return (
		<Grid item xs={6} md={2}>
			<div className="btn btn-account btn-list text-app" onClick={RemoveItem}>
				<h3>
					<XSquare /> Remove
				</h3>
			</div>
		</Grid>
	);

	function RemoveItem() {
		request.getRaw(`Settings/RemoveLink/${props.Id}`)
			.then(response => {
				response.text().then((data: any) => {
				});
			});
	}
}

export default RemoveLink;