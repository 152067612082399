import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, TransactionItem } from "../../types";

interface TransactionState {
	value: TransactionItem[] | undefined
}

const initialState: TransactionState = {
	value: undefined
};

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        set: (state = initialState, action: PayloadAction<TransactionItem[] | undefined>) => {
            let value: TransactionItem[] = action?.payload ?? [];
            for (let i = 0; i < value.length; i++) {
                value[i] = { ...value[i], transactionDate: new DateTime(value[i].transactionDate) };
            }
            state.value = value;
        },
		add: (state = initialState, action: PayloadAction<TransactionItem>) => {
            let value: TransactionItem[] = state.value ?? [];
            let newItem = action.payload as TransactionItem;
            newItem = { ...newItem, transactionDate: new DateTime(newItem.transactionDate) };
            state.value = [
                ...value, newItem
            ];
			return state;
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
			let value: TransactionItem[] = state.value ?? [];
			state.value = [
				...value.filter((item: TransactionItem) => {
					return item.accountTransactionId !== action.payload;
                })
            ];
			return state;
        }
    }
});

export const { set, add, remove } = transactionSlice.actions;

export default transactionSlice.reducer;