import { useState } from "react";
import { BudgetItem, CurrentUser, FamilyHasAccess, SelectableItem, UserItem } from "../../types";
import { Grid } from "@mui/material";
import Dropdown from "../Inputs/Dropdown";
import { Features } from "../../constants";
import { useSelector } from "react-redux";
import Toggle from "../Inputs/Toggle";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditUser = (props: any) => {
	let [user, setUser] = useState(props.Item as UserItem);
	let currentUser: CurrentUser = useSelector((state: any) => state.userData.value);
	let budgets = props.Budgets;

	return (<>
		<ModalHeader Title="Edit User" CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(user)} />
		<div className="modal-body mt-5">
			<Grid container>
				{!user.isMe && <>
					<Toggle Size={12} OnChange={UpdateIsActive} Checked={user.isActive} Label="Active" />
					<Toggle Size={12} OnChange={UpdateIsAdmin} Checked={user.isAdmin} Label="Full Access" />
				</>}
				{FamilyHasAccess(currentUser, Features.CheckRequests) &&
					<Toggle Size={12} OnChange={UpdateCanApproveChecks} Checked={user.canApproveChecks} Label="May Approve Check Requests" />
				}
				<Dropdown Size={12}
					Class="mb-0 mt-5"
					Label="Assigned Budgets"
					Options={budgets
						.map((b: BudgetItem) => {
							return {
								Id: b.budgetId,
								Value: b.description
							} as SelectableItem;
						})}
					Index={0}
					Value={user.budgetIds}
					Multiple
					OnChange={UpdateBudgets} />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(user)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		return true;
	}

	function UpdateIsActive(event: any, newValue: boolean) {
		let item = ({ ...user, isActive: newValue });
		setUser(item);
		props.OnChange(item);
	}
	function UpdateIsAdmin(event: any, newValue: boolean) {
		let item = ({ ...user, isAdmin: newValue });
		setUser(item);
	}
	function UpdateCanApproveChecks(event: any, newValue: boolean) {
		let item = ({ ...user, canApproveChecks: newValue });
		setUser(item);
	}
	function UpdateBudgets(newValue: any) {
		let item = ({ ...user, budgetIds: newValue });
		setUser(item);
	}
}

export default EditUser;