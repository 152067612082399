import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, TransactionItem } from "../../types";

interface CheckRequestState {
	value: TransactionItem[] | undefined
}

const initialState: CheckRequestState = {
	value: undefined
};

export const checkRequestSlice = createSlice({
    name: 'checkRequests',
    initialState,
    reducers: {
		setCheckRequests: (state = initialState, action: PayloadAction<TransactionItem[] | undefined>) => {
			let value: TransactionItem[] = action?.payload ?? [];
			for (let i = 0; i < value.length; i++) {
				value[i] = {
					...value[i],
					transactionDate: new DateTime(value[i].transactionDate),
					checkRequest: {
						...value[i].checkRequest!,
						dateNeeded: new DateTime(value[i].checkRequest!.dateNeeded),
						createdDate: new DateTime(value[i].checkRequest!.createdDate)
					}
				};
            }
            state.value = value;
        },
		addCheckRequest: (state = initialState, action: PayloadAction<TransactionItem>) => {
			let value: TransactionItem[] = state.value ?? [];
			let newItem = action.payload as TransactionItem;
			newItem = {
				...newItem,
				transactionDate: new DateTime(newItem.transactionDate),
				checkRequest: {
					...newItem.checkRequest!,
					dateNeeded: new DateTime(newItem.checkRequest!.dateNeeded),
					createdDate: new DateTime(newItem.checkRequest!.createdDate)
				}
			};
            state.value = [
                ...value, newItem
            ];
			return state;
        },
		removeCheckRequest: (state = initialState, action: PayloadAction<number>) => {
			let value: TransactionItem[] = state.value ?? [];
			state.value = [
				...value.filter((item: TransactionItem) => {
					return item.checkRequest!.accountTransactionCheckRequestId !== action.payload;
                })
            ];
			return state;
        }
    }
});

export const { setCheckRequests, addCheckRequest, removeCheckRequest } = checkRequestSlice.actions;

export default checkRequestSlice.reducer;