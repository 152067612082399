import { useEffect, useState } from "react";
import Description from "../Inputs/Description";
import { BudgetItem, CategoryItem, TransactionItem, USCurrency, VisuallyHiddenInput } from "../../types";
import { Backdrop, Button, CircularProgress, Grid, Skeleton } from "@mui/material";
import Divider from "../Inputs/Divider";
import { useItems } from "../../features/Fetch/Items";
import { DownloadPdf } from "../../features/ReportService";
import ModalHeader from "./ModalHeader";
import ModalFooterCustom from "./ModalFooterCustom";
import { useFetch } from "../../features/Fetch/Fetch";
import InnerImageZoom from "react-inner-image-zoom";
import { CloudUploadFill } from "react-bootstrap-icons";
import FileResizer from "react-image-file-resizer";

const ApproveCheckRequest = (props: any) => {
	let [checkRequest, setCheckRequest] = useState(props.Transaction as TransactionItem);
	let [overlay, setOverlay] = useState(false);
	let [fetching, setFetching] = useState(true);
	let budgets: BudgetItem[] = props.Budgets;
	let categories: CategoryItem[] = props.Categories;
	let repo = useItems();
	let request = useFetch();

	useEffect(() => {
		if (checkRequest.checkRequest!.accountTransactionCheckRequestId > 0) {
			request
				.get(`Transactions/Receipt/${checkRequest.accountTransactionId}`)
				.then((receipt: TransactionItem) => {
					let item = {
						...checkRequest,
						receipts: receipt.receipts,
					};
					setCheckRequest({
						...item
					});
					setFetching(false);
				});
		} else {
			setFetching(false);
		}
	}, []);

	let PDFJS = require('pdfjs-dist/webpack');

	return (<>
		<ModalHeader Title="Approve Request" CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(checkRequest)} />
		<div className="modal-body mt-5">
			<Grid container className="mt-3">
				{(fetching) ?
					<>
						Retrieving Invoice...
						<Skeleton variant='rounded' width='100%' height={600} />
					</> :
					<></>}
				{checkRequest.receipts.map((receipt: any) => {
					var rowNum = checkRequest.receipts.indexOf(receipt);
					return (
						<div className="col-12 centered">
							<InnerImageZoom src={`data:image/bmp;base64,${receipt}`} className="img-zoom mb-3" zoomType="hover" height={600} hideHint={true} zoomScale={1.5} />
							<Button
								className="mb-0"
								component="label"
								variant="text"
								onClick={() => RemoveReceipt(rowNum)}>
								Remove
							</Button>
							<Divider />
						</div>
					);
				})}
				<Grid item xs={12}>
					<Button className="mb-3 centered" component="label" variant="contained" startIcon={<CloudUploadFill />}>
						Add Image
						<VisuallyHiddenInput type="file" onChange={UploadFile} />
					</Button>
				</Grid>
				<Grid item xs={12} className="mb-1">Request Date: {checkRequest.checkRequest!.createdDate.toString()}</Grid>
				<Grid item xs={12} className="mb-1">Date Needed: {checkRequest.checkRequest!.dateNeeded.toString()}</Grid>
				<Grid item xs={12} className="mb-1">Requested By: {checkRequest.created}</Grid>
				{props.Transaction.checkRequest!.approvalState > 0 && <Grid item xs={12} className="mb-1">Approved By: {checkRequest.checkRequest!.reviewer}</Grid>}
				<Divider />
				<Grid item xs={12} className="mb-1">Pay to the order of:</Grid>
				<Grid item xs={12} className="mb-1">{checkRequest.merchant}</Grid>
				{checkRequest.checkRequest!.mailingAddress && checkRequest.checkRequest!.mailingAddress.length > 0 && <>
					<Grid item xs={12} className="mb-1">{checkRequest.checkRequest!.mailingAddress}</Grid>
					<Grid item xs={12} className="mb-1">{checkRequest.checkRequest!.city}, {checkRequest.checkRequest!.stateName} {checkRequest.checkRequest!.zipCode}</Grid>
				</>}
				<Divider />
				<Grid item xs={12} className="mb-1">Check {checkRequest.checkRequest!.needsMailed ? "needs" : "does not need"} to be mailed</Grid>
				<Divider />
				{checkRequest.budgetCategoryAmounts.map(bca => <>
					<Grid item xs={6} className="mb-1">{budgets.find(b => b.budgetId === bca.budgetId)?.description}</Grid>
					<Grid item xs={6} className="mb-1 px-3" style={{ textAlign: "right" }}>{USCurrency(bca.amount)}</Grid>
					<Grid item xs={6} className="mb-1">{categories.find(c => c.categoryId === bca.categoryId)?.description}</Grid>
					<Grid item xs={12} className="mb-0 mt-3">Additional Info: {bca.details}</Grid>
					<Divider />
				</>)}
				<Grid item xs={12} className="centered mb-5">
					{props.Reject &&
						<Button variant={checkRequest.checkRequest!.approvalState === -1 ? "contained" : "outlined"} onClick={() => UpdateApproved(false)}>{props.Reject}</Button>
					}
					{props.Approve &&
						<Button variant={checkRequest.checkRequest!.approvalState === props.ApproveValue ? "contained" : "outlined"} onClick={() => UpdateApproved(true)}>{props.Approve}</Button>
					}
				</Grid>
				<Description Size={12} Label="Note" Value={checkRequest.checkRequest!.approvalNote} OnChange={(val: any) => UpdateDetails(val.target.value)} />
				{props.Transaction.checkRequest!.approvalState > 0 &&
					<Grid item xs={12}>
						<Button onClick={() => Export()}>
							Download Report
						</Button>
					</Grid>
				}
			</Grid>
		</div >
		<ModalFooterCustom HideSave={props.ApproveValue === props.Transaction.approvalState} ShowSave={ShouldShowSave()} Save={() => props.OnSave(checkRequest)} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
	</>);

	function ShouldShowSave() {
		return checkRequest.checkRequest!.approvalState === -1 || checkRequest.checkRequest!.approvalState === props.ApproveValue;
	}

	function UpdateDetails(newValue: string) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest.checkRequest!, approvalNote: newValue } });
		setCheckRequest(item);
	}
	function UpdateApproved(newValue: boolean) {
		let item = ({ ...checkRequest, checkRequest: { ...checkRequest.checkRequest!, approvalState: newValue ? props.ApproveValue : -1 } });
		setCheckRequest(item);
	}

	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		let { files } = event.target;
		let file = (files as FileList)[0];
		if (file.type.startsWith("image")) {
			let resizedFile = await new Promise(resolve =>
				FileResizer.imageFileResizer(file, 1280, 1280, file.type.replace("image/", ""), 100, 0, uri => resolve(uri))
			);
			let item = {
				...checkRequest,
				receipts: [...checkRequest.receipts, resizedFile!.toString().substring(resizedFile!.toString().indexOf(',') + 1)],
				hasReceipt: true
			};
			setCheckRequest(item);
		} else {
			let data = await ReadFileData(file);
			let pdfjs = await PDFJS;
			let pdfPromise = await pdfjs.getDocument(data).promise;
			let pdf = await pdfPromise;
			let canvas = document.createElement("canvas");
			let page = await pdf.getPage(1);
			let viewport = page.getViewport({ scale: 1 });
			let context = canvas.getContext("2d");
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			await page.render({ canvasContext: context, viewport: viewport }).promise;
			canvas.toBlob((result) => {
				new Promise(resolve =>
					FileResizer.imageFileResizer(result!, 1280, 6400, 'jpg', 100, 0, uri => resolve(uri))
				).then((resizedFile: any) => {
					let item = {
						...checkRequest,
						receipts: [...checkRequest.receipts, resizedFile?.toString()?.substring(resizedFile.toString().indexOf(',') + 1)],
						hasReceipt: true
					};
					setCheckRequest(item);
				});
			});
		}
	}
	function ReadFileData(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(e.target!.result);
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsDataURL(file);
		});
	};

	function RemoveReceipt(idx: number) {
		let rct: string[] = [...(checkRequest.receipts)];
		rct.splice(idx, 1);;
		let item = ({ ...checkRequest, receipts: rct });
		setCheckRequest(item);
	}

	async function Export() {
		setOverlay(true);
		setTimeout(() => GenerateReport(), 50);
	}

	async function GenerateReport() {
		let file = await repo.GetCheckRequestPdfAsync(checkRequest.accountTransactionId);
		DownloadPdf(await file.blob() as Blob, "Check Request");
		setOverlay(false);
	}
}
export default ApproveCheckRequest;