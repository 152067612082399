import { CurrentUser, LocalData } from "../../types";
interface StoreModel {
    StoreName: string,
    StoreUserName: string,
	StoreReportName: string,
	LoadLocalData: () => LocalData | undefined,
	SaveLocalData: (newData: LocalData) => void,
	ClearLocalData: () => void,
	LoadUserData: () => CurrentUser | undefined,
	SaveUserData: (user: CurrentUser) => void,
	ClearUserData: () => void,
}

export var Storage: StoreModel = {
    StoreName: 'MultiBudget',
	StoreUserName: 'MultiBudgetU',
	StoreReportName: 'MultiBudgetR',
	LoadLocalData() {
		let storage = localStorage.getItem(this.StoreName);
		if (!storage)
			return;
		let data: LocalData = JSON.parse(storage);
		return data;
	},
	SaveLocalData(newData) {
		let data = { ...newData };

		localStorage.setItem(this.StoreName, JSON.stringify(data));
	},
	ClearLocalData() {
		let storage = localStorage.getItem(this.StoreName);
		if (!storage)
			return;
		localStorage.removeItem(this.StoreName);
	},
    LoadUserData() {
        let storage = localStorage.getItem(this.StoreUserName);
        if (!storage)
			return;
		let data: CurrentUser = JSON.parse(storage);
        return data;
    },
    SaveUserData(user) {
		let storage = localStorage.getItem(this.StoreUserName);
		let data = {} as CurrentUser;
        if (storage) {
            data = JSON.parse(storage);
        }

		data = { ...user };

        localStorage.setItem(this.StoreUserName, JSON.stringify(data));
    },
    ClearUserData() {
        let storage = localStorage.getItem(this.StoreUserName);
        if (!storage)
            return;
        localStorage.removeItem(this.StoreUserName);
	}
};