/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckRequest from "../components/ListItems/CheckRequest";
import EditCheckRequest from "../components/Modals/EditCheckRequest";
import { FamilyHasAccess, CurrentUser, DateTime, UserItem, CategoryItem, TransactionItem, BudgetCategoryAmount, TransactionCheckRequestItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid, IconButton } from "@mui/material";
import { PlusLg } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import {  useNavigate } from "react-router-dom";
import { pageLoad } from "../App";
import { useAuth } from "../features/Fetch/Auth";
import { Features } from "../constants";
import ApproveCheckRequest from "../components/Modals/ApproveCheckRequest";

const CheckRequests = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetCheckRequestsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let navigate = useNavigate();

	let budgetTree = repo.GetBudgetTree();
	let checkRequests = repo.SortedCheckRequests()?.filter(c => c.isMine && c.checkRequest!.approvalState !== 2);
	let finalizedRequests = repo.SortedCheckRequests()?.filter(c => c.checkRequest!.approvalState === 2);

	if (!FamilyHasAccess(user, Features.CheckRequests)) {
		navigate('/');
		return <></>;
	}

	if (location.hash === '#new' && !modal.IsOpen) {
		OpenNewModal();
		navigate('/CheckRequests');
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center" className="">
				<Grid item xs={9}>
					<h2 className="mt-2 text-app">Check Requests</h2>
				</Grid>
				<Grid item xs={2}>
					{!checkRequests && (< p ><em>Loading...</em></p>)}
					{checkRequests && (
						<IconButton
							className="text-app"
							aria-label='new menu'
							onClick={OpenNewModal}>
							<PlusLg />
						</IconButton>
					)}
				</Grid>
				{checkRequests != null && (
					<Grid container>
						{checkRequests.map(checkRequest => {
							return (
								<div className="w-100" key={`crdiv${checkRequest.checkRequest!.accountTransactionCheckRequestId}`}>
									<CheckRequest
										Item={checkRequest}
										OnClick={() => OpenModal(checkRequest.checkRequest!.accountTransactionCheckRequestId)}
										key={`checkRequest${checkRequest.checkRequest!.accountTransactionCheckRequestId}`} />
								</div>
							);
						})}
					</Grid>
				)}
				{user.isHoH && finalizedRequests != null && (
					<Grid container>
						{finalizedRequests.map(checkRequest => {
							return (
								<div className="w-100" key={`crdiv${checkRequest.checkRequest!.accountTransactionCheckRequestId}`}>
									<CheckRequest
										Item={checkRequest}
										OnClick={() => OpenPrintModal(checkRequest.checkRequest!.accountTransactionCheckRequestId)}
										key={`checkRequest${checkRequest.checkRequest!.accountTransactionCheckRequestId}`} />
								</div>
							);
						})}
					</Grid>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	async function SaveCheckRequest(item: TransactionItem) {
		HideModal();
		await repo.UpdateCheckRequestAsync(item, item.checkRequest!.accountTransactionCheckRequestId === 0, false);
		repo.GetCheckRequestsAsync();
	}

	function RejectCheckRequest(item: TransactionItem) {
		item.needsMyApproval = false;
		repo.ApproveCheckRequestAsync(item);
		HideModal();
		repo.GetCheckRequestsAsync();
	}

	function DeleteCheckRequest(item: TransactionItem) {
		repo.UpdateCheckRequestAsync(item, false, true);
		HideModal();
	}

	function OpenNewModal() {
		let selectedBudget = budgetTree?.find(b => b.isMine);
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => b.budgetId === 0);
		}
        let checkRequest = {
			accountTransactionId: 0,
			merchant: "",
			receipt: undefined,
			receiptEmailId: undefined,
			hasReceipt: false,
			budgetCategoryAmounts: [{ budgetId: selectedBudget?.budgetId, categoryId: selectedBudget?.categories[0].categoryId, amount: 0 } as BudgetCategoryAmount],
			accountId: 0,
			transactionDate: new DateTime(),
			isMine: true,
			needsMyApproval: false,
			needsReimbursement: false,
			completedReimbursement: false,
			isIncome: false,
			created: "",
			lastUpdated: "",
			color: "",
			isMiles: false,
			isCheckRequest: true,
			checkRequest: {
				accountTransactionCheckRequestId: 0,
				amount: 0,
				reviewerId: 0,
				dateNeeded: new DateTime(),
				needsMailed: false,
				approvalState: 0,
				needsMyApproval: false,
				isMine: true,
				created: '',
				createdDate: new DateTime()
			} as TransactionCheckRequestItem
		};
		modal = {
			...modal,
			Body: <EditCheckRequest CheckRequest={checkRequest} Budgets={budgetTree} Users={users.filter(u => u.canApproveChecks)} OnSave={SaveCheckRequest} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenModal(idx: number) {
		let checkRequest = checkRequests!.find(t => t.checkRequest!.accountTransactionCheckRequestId === idx);
		if (checkRequest) {
			modal = {
				...modal,
				Body: <EditCheckRequest CheckRequest={checkRequest} Budgets={budgetTree} Users={users.filter(u => u.canApproveChecks)} OnSave={SaveCheckRequest} OnDelete={DeleteCheckRequest} Close={HideModal} />,
                IsOpen: true,
                WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenPrintModal(idx: number) {
		let transaction = finalizedRequests!.find(t => t.checkRequest!.accountTransactionCheckRequestId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <ApproveCheckRequest Transaction={transaction} Budgets={budgetTree} Categories={categories} ApproveValue={1} Reject="Cancel Check and Send Back" Close={HideModal} OnSave={RejectCheckRequest} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default CheckRequests;