/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Transaction from "../components/ListItems/Transaction";
import { BudgetCategoryAmount, BudgetItem, CategoryItem, CurrentUser, FamilyFeatureValue, FamilyHasAccess, TransactionItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid } from "@mui/material";
import { ExclamationCircle } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import ApproveTransaction from "../components/Modals/ApproveTransaction";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import CheckRequest from "../components/ListItems/CheckRequest";
import Divider from "../components/Inputs/Divider";
import ApproveCheckRequest from "../components/Modals/ApproveCheckRequest";

const Approval = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
				repo.GetCheckRequestsAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let navigate = useNavigate();

	let transactions = useSelector((state: any) => state.transactions.value) as TransactionItem[];
	transactions = transactions?.filter(t => t.needsMyApproval) ?? [] as TransactionItem[];
	let checkRequests = useSelector((state: any) => state.checkRequests.value) as TransactionItem[];
	checkRequests = checkRequests?.filter(t => t.needsMyApproval || t.checkRequest!.needsMyApproval) ?? [] as TransactionItem[];

	if (!FamilyHasAccess(user, Features.Transactions)) {
		navigate("/");
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center" className="">
				<Grid item xs={2}>
				</Grid>
				<Grid item xs={6}>
					<h2 className="mt-2 text-app">Approvals</h2>
				</Grid>
				<Grid item xs={3}>
				</Grid>
				{categories != null && (
					<>
						{transactions.length === 0 && checkRequests.length === 0 && (
							<Grid container onClick={GoHome} className="cursor-pointer">
								<Grid item xs={2}>
									<ExclamationCircle />
								</Grid>
								<Grid item xs={10}>
									There are no more approvals. Click here to return to {FamilyFeatureValue(user, Features.TransactionTitle)}s.
								</Grid>
							</Grid>
						)}
						{(transactions.length > 0 || checkRequests.length > 0) && (

							<Grid container>
								<>
									{checkRequests.length > 0 && <h4 className="centered mt-3 text-app">Check Requests</h4>}
									{
										checkRequests.map(transaction => {
											return (
												<div className="w-100" key={`crdiv${transaction.checkRequest!.accountTransactionCheckRequestId}`}>
													<CheckRequest
														Item={transaction}
														OnClick={() => transaction.checkRequest!.approvalState === 1 ? OpenFinalCrModal(transaction.checkRequest!.accountTransactionCheckRequestId) : OpenCrModal(transaction.checkRequest!.accountTransactionCheckRequestId)}
														key={`dr${transaction.checkRequest!.accountTransactionCheckRequestId}`} />
												</div>
											);
										})
									}
									{checkRequests.length > 0 && transactions.length > 0 && <Divider />}
									{transactions.length > 0 && <h4 className="centered mt-3 text-app">{FamilyFeatureValue(user, Features.TransactionTitle)}s</h4>}
									{
										transactions.map(transaction => {
											return (
												<div className="w-100" key={`transactdiv${transaction.accountTransactionId}`}>
													<Transaction
														Item={transaction}
														Color={transaction.color}
														Categories={categories}
														OnClick={() => OpenModal(transaction.accountTransactionId)}
														key={`transaction${transaction.accountTransactionId}`} />
												</div>
											);
										})
									}
								</>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function GoHome() {
		navigate("/");
	}

	function SaveTransaction(item: TransactionItem) {
		repo.ApproveTransactionAsync(item);
		HideModal();
		repo.GetTransactionsAsync(-1);
	}

	function SaveCheckRequest(item: TransactionItem) {
		item.needsMyApproval = false;
		repo.ApproveCheckRequestAsync(item);
		HideModal();
		repo.GetCheckRequestsAsync();
	}

	function OpenModal(idx: number) {
		let transaction = { ...transactions.find(t => t.accountTransactionId === idx) } as TransactionItem;
		if (transaction) transaction.budgetCategoryAmounts = transaction.budgetCategoryAmounts!.map<BudgetCategoryAmount>((b: BudgetCategoryAmount) => {
			return {
				...b
			};
		});
		if (transaction) {
			modal = {
				...modal,
				Body: <ApproveTransaction Mileage={transaction.miles != null} Transaction={transaction} Budgets={budgets} Categories={categories} OnSave={SaveTransaction} Close={HideModal} />,
                IsOpen: true,
                WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenCrModal(idx: number) {
		let transaction = checkRequests.find(t => t.checkRequest!.accountTransactionCheckRequestId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <ApproveCheckRequest Transaction={transaction} Budgets={budgets} Categories={categories} Approve="Approve" Reject="Reject" ApproveValue={1} OnSave={SaveCheckRequest} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenFinalCrModal(idx: number) {
		let transaction = checkRequests.find(t => t.checkRequest!.accountTransactionCheckRequestId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <ApproveCheckRequest Transaction={transaction} Budgets={budgets} Categories={categories} Approve="Check Complete" Reject="Send Back to Requester" ApproveValue={2} OnSave={SaveCheckRequest} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default Approval;