/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditBudget from "../components/Modals/EditBudget";
import { BudgetCategoryAmount, BudgetItem, BudgetVariant, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, ReceiptEmailItem, TimeframeItem, TransactionItem, USCurrency } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Button, Grid } from "@mui/material";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import BudgetReport from "../components/Modals/BudgetReport";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import moment, { Moment } from "moment";
import Divider from "../components/Inputs/Divider";
import Transaction from "../components/ListItems/Transaction";
import EditTransaction from "../components/Modals/EditTransaction";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const BudgetTransactions = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();
	let dispatch = useDispatch();

	let routeInfo = location.pathname.toLowerCase().split('/');
	let budgetId = parseInt(routeInfo[2]);
	let routeDate = moment(routeInfo[3], 'YYYY-MM-DD', true);
	if (isNaN(budgetId) || budgetId < 1 || routeInfo.length < 4 || !routeDate.isValid()) {
		navigate('/budgets');
	}

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				UpdateDate(routeDate);
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		} else {
			navigate('/budgets');
		}
	}, []);

	let [currentDate, setCurrentDate] = useState(new DateTime());
	let curDate: DateTime = new DateTime();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions = repo.SortedTransactions(null) ?? undefined;
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let budgetTree = repo.GetBudgetTree();
	let receiptEmails: ReceiptEmailItem[] = useSelector((state: any) => state.receiptEmails.value);

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}

	let hasExternal = (transactions?.filter(t => t.isExternal)?.length ?? 0) > 0;

	let budget = budgets.find(b => b.budgetId === budgetId);
	if (budget) {
		transactions = transactions?.filter(t => t.transactionDate >= budget!.variant.startDate && t.transactionDate < budget!.endDate! && t.budgetCategoryAmounts.map(c => c.budgetId).includes(budgetId));
	} else {
		transactions = [] as TransactionItem[];
	}

	let catString = categories.filter((c: CategoryItem) => budget?.categoryIds.includes(c.categoryId))!.map((c: CategoryItem) => c.description).join(", ")
	catString = catString.length < 18 ?
		catString :
		`${catString.substring(0, 15)}...`;

	return (
		<>
			<Grid container columnSpacing={2} justifyContent="center" className='p-3' style={{ color: budget?.color }}>
				<Grid item xs={12}>
					<h2 className="mt-2 mb-3">{budget?.description}</h2>
				</Grid>
				{budget && <>
					<Grid item xs={12}>
						Categories: {catString}
					</Grid>
					<Grid item xs={12} className="text-success">
						{USCurrency(budget!.amountSpent)}
						{(budget!.variant.amount > 0) && <> of {USCurrency(budget!.variant.amount).substring(1)}</>}
					</Grid>
					<Grid item xs={12} className={`mb-5 ${budget!.amountRemaining > 0 ? 'text-app' : 'text-danger'}`}>
						{(budget!.variant.amount > 0) && <>{USCurrency(Math.abs(budget!.amountRemaining))} {budget!.amountRemaining >= 0 ? "left" : "over"}</>}
					</Grid>
					<Grid item xs={3} className='mb-3 text-app centered small'>
						<ChevronLeft className="h3" onClick={BackDate} />
					</Grid>
					<Grid item xs={6} className='mb-3 text-app centered small'>
						{budget!.variant.startDate.toString()} to {budget!.endDate!.addDays(-1).toString()}
					</Grid>
					<Grid item xs={3} className='mb-3 text-app centered small'>
						<ChevronRight className="h3" onClick={ForwardDate} />
					</Grid>
					{user.isHoH &&
						<Grid item xs={6}>
							<Button fullWidth variant="contained" onClick={OpenBudgetModal} className="bg-app text-white btn-list">Edit Budget</Button>
						</Grid>
					}
					<Grid item xs={6}>
						<Button fullWidth variant="contained" onClick={OpenModal} className="bg-app text-white btn-list">View Report</Button>
					</Grid>
				</>}
			</Grid>
			<Grid container rowSpacing={3} justifyContent="center" className="mt-3">
				<Grid item xs={12} className='centered'>
					<h3 className="mt-2 text-app">Transactions</h3>
				</Grid>
				{transactions != null && (
					<>
						{transactions.length > 0 && (
							<Grid container>
								<>
									{
										transactions.map(transaction => {
											return (
												<div className="w-100" key={`transactdiv${transaction.accountTransactionId}`}>
													{!IsCurrentDate(transaction) && (
														<Divider
															Text={curDate.toString()}
															key={`date${transaction.accountTransactionId}`} />
													)}
													<Transaction
														Item={transaction}
														Color={transaction.color}
														Categories={categories}
														HasExternal={hasExternal}
														OnClick={() => OpenTransactionModal(transaction.accountTransactionId)}
														key={`transaction${transaction.accountTransactionId}`} />
												</div>
											);
										})
									}
								</>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function BackDate() {
		let start = budget!.variant.startDate.addDays(-1);
		repo.GetBudgetsWithDateAsync(start);
		setCurrentDate(start);
	}

	function ForwardDate() {
		let end = budget!.endDate!;
		repo.GetBudgetsWithDateAsync(end);
		setCurrentDate(end);
	}


	//Budgets
	function SaveBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		let variant = { ...item.variant };
		repo.UpdateBudgetAsync(item, variant, item.budgetId === 0, false);
		HideModal();
		repo.GetBudgetsWithDateAsync(currentDate);
	}

	function DeleteBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		repo.UpdateBudgetAsync(item, item.variant, false, true);
		HideModal();
		repo.GetBudgetsWithDateAsync(currentDate);
	}

	function OpenModal() {
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				categoryIds: budget.categoryIds,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;
			modal = {
				...modal,
				Body: <BudgetReport Budget={modalBudget} Categories={categories} Transactions={transactions} CheckRequests={checkRequests} StartDate={modalBudget.variant.startDate} EndDate={new DateTime()} User={user} OpenBudget={() => OpenBudgetModal()} Close={HideModal} ViewReport={ViewReport} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenBudgetModal() {
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				categoryIds: budget.categoryIds,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				isViewTransactions: budget.isViewTransactions,
				isIncome: budget.isIncome,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;
			modal = {
				...modal,
				Body: <EditBudget Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} OnDelete={DeleteBudget} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function ViewReport() {
		HideModal();
		navigate("/report/categories");
	}

	//Transactions
	async function SaveTransaction(item: TransactionItem) {
		HideModal();
		let bcas = [] as BudgetCategoryAmount[];
		for (let i = 0; i < item.budgetCategoryAmounts.length; i++) {
			let amount = { ...item.budgetCategoryAmounts[i] };
			if (item.miles && item.miles > 0) {
				amount.amount = item.miles * (item.rate ?? 0);
			}
			bcas = [...bcas, amount]
		}
		let rec = item.recurringTransaction;
		if (rec) {
			rec.accountId = item.accountId;
			rec.budgetId = item.budgetCategoryAmounts[0].budgetId;
			rec.categoryId = item.budgetCategoryAmounts[0].categoryId;
			rec.description = item.merchant;
			rec.nextAmount = item.budgetCategoryAmounts.reduce((total, t) => total + t.amount, 0);
			rec.nextDate = item.transactionDate.addDays(rec.days).addMonths(rec.months);
		}
		item = {
			...item,
			budgetCategoryAmounts: bcas,
			recurringTransaction: rec
		};
		if (item.receiptEmailId) {
			repo.UpdateReceiptEmailAsync(receiptEmails.find(r => r.receiptEmailId === item.receiptEmailId)!, false, true);
		}
		if (item.importedTransactionId) {
			repo.UpdateTransactionAsync(transactions!.find(r => r.accountTransactionId === item.importedTransactionId)!, false, true);
		}
		await repo.UpdateTransactionAsync(item, item.accountTransactionId === 0, false);
		repo.GetBudgetsAsync();
		repo.GetCategoriesAsync();
	}

	function DeleteTransaction(item: TransactionItem) {
		repo.UpdateTransactionAsync(item, false, true);
		HideModal();
	}

	function OpenTransactionModal(idx: number) {
		let transaction = transactions!.find(t => t.accountTransactionId === idx);
		if (transaction) {
			modal = {
				...modal,
				Body: <EditTransaction Mileage={transaction.miles != null} ReceiptEmails={receiptEmails} Transaction={transaction} Budgets={budgetTree} Transactions={transactions?.filter(t => !t.isExternal)} Categories={categories} OnSave={SaveTransaction} OnDelete={DeleteTransaction} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}


	function UpdateDate(newValue: Moment) {
		let val = new DateTime(newValue);
		repo.GetBudgetsWithDateAsync(val);
		setCurrentDate(val);
	}

	function IsCurrentDate(transaction: TransactionItem) {
		if (curDate.Value.getTime() !== new DateTime(transaction.transactionDate.Value).startOfDay().Value.getTime()) {
			curDate = new DateTime(transaction.transactionDate.Value).startOfDay();
			return false;
		}
		return true;
	}
}

export default BudgetTransactions;