import React, { useState } from 'react';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';

const PasswordInput = (props: any) => {
	const [show, setShow] = useState(false);

	return (
		<Grid item xs={props.Size ?? 12}>
			<TextField
				id="password"
				name="password"
				autoComplete='password'
				variant="outlined"
				className={`form-control border-app ${props.Class}`}
				fullWidth
				label={(props.NoLabel ? "" : props.Label ?? "Email")}
				type={show ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label='toggle password visibility'
								onClick={HandleShowClicked}
								onMouseDown={HandleShowMouseDown}
								edge="end">
								{show ? <EyeSlashFill /> : <EyeFill />}
							</IconButton>
						</InputAdornment>
					)
				}}
				value={props.Value}
				onKeyUp={props.OnKeyUp}
				onChange={props.OnChange} />
		</Grid>
	);

	function HandleShowClicked() {
		setShow((s) => !s);
	}
	function HandleShowMouseDown(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
	}
}

export default PasswordInput;