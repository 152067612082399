import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalProps {
    IsOpen: boolean,
    WasOpen: boolean,
	Body: JSX.Element,
}

interface ModalSliceState {
	value: ModalProps
}

const initialState: ModalSliceState = {
	value: {
		Title: '',
        IsOpen: false,
        WasOpen: false,
		IsReport: false,
		CanDelete: false,
		ShowSave: false,
		Body: <></>
	} as ModalProps
};

export const userDataSlice = createSlice({
    name: 'modal',
    initialState,
	reducers: {
		setProps: (state = initialState, action: PayloadAction<ModalProps>) => {
            state.value = action.payload;
        }
    }
});

export const { setProps } = userDataSlice.actions;

export default userDataSlice.reducer;