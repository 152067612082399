import { Chip, Grid } from "@mui/material";
import { UserItem } from "../../types";

const User = (props: any) => {
	let item: UserItem = props.Item;

	return (
		<Grid container className={`btn-list ${props.Class}`} onClick={props.OnClick}>
			<Grid item xs={2} className="centered" >
				<Chip className={item.isActive ? "bg-success" : "bg-default"} />
			</Grid>
			<Grid item xs={6} >
				<h3>{item.firstName} {item.lastName}</h3>
			</Grid>
			<Grid item xs={4} className="right">
				{item.isAdmin ? "Full" : "Limited"}
			</Grid>
		</Grid >
    );
}

export default User;