import {  useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

const NavBar = (props: any) => {
	let navigate = useNavigate();

	return (
		<h2>
			<BottomNavigation showLabels style={{ background: "transparent" }}>
				<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label={props.TransactionTitle} icon={<Icon.CurrencyDollar />} onClick={() => navigate("/")} />
				<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label="Budgets" icon={<Icon.PiggyBank />} onClick={() => navigate("/budgets")} />
				<BottomNavigationAction style={{ color: "white", fontWeight: "bolder" }} label="Reports" icon={<Icon.FileBarGraph />} onClick={() => navigate("/reports")} />
			</BottomNavigation>
		</h2>
		);
}

export default NavBar;