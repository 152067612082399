import { useState } from "react";
import { FamilyCode } from "../../types";
import { Grid } from "@mui/material";
import { Clipboard } from "react-bootstrap-icons";
import EmailInput from "../Inputs/EmailInput";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditCode = (props: any) => {
	let [code] = useState(props.Item as FamilyCode);
	let [email, setEmail] = useState("");

	return (<>
		<ModalHeader Title="Family Code" CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(code)} />
		<div className="modal-body mt-5">
			<Grid container justifyContent="center">
				{code.familyCodeId > 0 && <>
					<Grid item xs={7}>
						<h1>{code.code}</h1>
					</Grid>
					<Grid item xs={2} onClick={CopyCode}>
						<Clipboard />
					</Grid>
					<Grid item xs={9}>
						{code.isAdmin ? "Full" : "Limited"}
					</Grid>
				</>}
				<Grid item xs={9}>
					<h3>{(code.familyCodeId > 0) ? "T" : "Requesting t"}his code will allow 1 user to sign up for your family account.</h3>
				</Grid>
				<Grid item xs={9}>
					<h3>When a new user registers with your family, they will not be active until you activate them.</h3>
				</Grid>
				<Grid item xs={9}>
					<h3>You can only activate users if the current number of active users in your family is less than your plan limit. When you reach your plan limit, you must deactive a user to activate a new user.</h3>
				</Grid>
				<EmailInput Id="inputEmail" Label="Email To (Optional)" Value={email} Size={8} OnChange={UpdateEmail} />
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={true} Save={() => props.OnSave(code, email)} Close={props.Close} />
	</>);

	function UpdateEmail(newValue: any) {
		setEmail(newValue.target.value);
	}

	async function CopyCode () {
		await navigator.clipboard.writeText(code.code);
		alert("Coppied to clipboard");
	}
}

export default EditCode;