import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { CategoryItem } from "../../types";
import Description from "../Inputs/Description";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditCategory = (props: any) => {
	let [category, setCategory] = useState(props.Item as CategoryItem);
	let [overlay, setOverlay] = useState(false);

	return (<>
		<ModalHeader Title={`${category.categoryId > 0 ? 'Edit' : 'Add'} Category`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(category)} />
		<div className="modal-body mt-5">
			<Description Label="Description" Value={category.description} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(category)} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
	</>);

	function ShouldShowSave() {
		return category.description !== null;
	}

	function UpdateDescription(newValue: any) {
		category = ({ ...category, description: newValue });
		setCategory(category);
	}
}

export default EditCategory