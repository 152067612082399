import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, TimeframeItem } from "../../types";

interface TimeframeState {
	value: TimeframeItem[] | undefined
}

const initialState: TimeframeState = {
    value: []
};

export const timeframeSlice = createSlice({
    name: 'timeframes',
    initialState,
    reducers: {
        set: (state = initialState, action: PayloadAction<TimeframeItem[] | undefined>) => {
            let value: TimeframeItem[] = action?.payload ?? [];
            for (let i = 0; i < value.length; i++) {
				value[i] = { ...value[i], startDate: new DateTime(value[i].startDate), endDate1: new DateTime(value[i].endDate1), endDate2: new DateTime(value[i].endDate2) };
            }
            state.value = value;
        },
        add: (state = initialState, action: PayloadAction<TimeframeItem>) => {
            let value: TimeframeItem[] = state.value ?? [];
			let newItem = action.payload as TimeframeItem;
			newItem = { ...newItem, startDate: new DateTime(newItem.startDate), endDate1: new DateTime(newItem.endDate1), endDate2: new DateTime(newItem.endDate2) };
            state.value = [
                ...value, newItem
            ];
			return state;
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
			let value: TimeframeItem[] = state.value ?? [];
			state.value = [
                ...value.filter((item: TimeframeItem) => {
                    return item.timeframeId !== action.payload;
                })
            ];
			return state;
        }
    }
});

export const { set, add, remove } = timeframeSlice.actions;

export default timeframeSlice.reducer;