import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../features/Fetch/Auth";
import { CheckEnterKey } from "../../pages/Security/Login";
import Error from "../Error";
import Divider from "../Inputs/Divider";
import EmailInput from "../Inputs/EmailInput";
import PasswordInput from "../Inputs/PasswordInput";

let initialStateVars = {
	Email: '',
	Password: ''
};

interface LoginProps {
	Logo: string,
	ForgotClicked: () => void,
	RegisterClicked: () => void,
	Verify: (e: string) => void
}
const Login = (props: LoginProps) => {
	let auth = useAuth();

	let [stateVars, setStateVars] = useState(initialStateVars);
	let [error, setError] = useState('');
	let [overlay, setOverlay] = useState(false);

	return (
		<>
			<div className="centered mt-20 text-success">
				<h5>Login to</h5>
			</div>
			<img className="img-title" src={props.Logo} alt="Shekl" />
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<Error Text={error} />
				</Grid>
				<EmailInput Required Id="inputEmail" Label="Email" Value={stateVars.Email} Size={8} OnChange={emailChange} OnKeyUp={(e: string) => CheckEnterKey(e, login)} />
				<PasswordInput Id="inputPassword" Label="Password" Value={stateVars.Password} Size={8} OnSubmit="@OnSubmit" OnChange={(e: string) => passwordChange(e)} OnKeyUp={(e: string) => CheckEnterKey(e, login)} />
				<Grid item className="centered" xs={12}>
					<Button variant="text" className="centered" onClick={props.ForgotClicked}>Forgot Password</Button>
					<br />
				</Grid>
				<Grid item xs={8}>
					<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={login}>Sign In</Button>
				</Grid>
				<Divider Text="OR" />
			</Grid>
			<h6 className="mt-3">Have a family code?</h6>
			<Button variant="text" className="centered" onClick={props.RegisterClicked}>SIGN UP</Button>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
				<CircularProgress />
			</Backdrop>
		</>
    );

	function emailChange(input: any) {
		setStateVars({
			...stateVars, Email: input.target.value
		});
	}

	function passwordChange(input: any) {
		setStateVars({
			...stateVars, Password: input.target.value
		});
	}

	async function login() {
		setOverlay(true);
		if (String(stateVars.Email).match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			let result = await auth.login({ Email: stateVars.Email, Password: stateVars.Password });
			if (result === 'Unverified') {
				props.Verify(stateVars.Email);
			} else {
				setError(result);
			}
			setOverlay(false);
		} else {
			setOverlay(false);
			setError('You must enter a valid email.');
		}
	}
};

export default Login;