/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { CurrentUser, FamilyFeatureValue, ReceiptEmailItem, VisuallyHiddenInput } from "../../types";
import { Backdrop, Button, CircularProgress, Grid, Skeleton } from "@mui/material";
import { CloudUploadFill, Map } from "react-bootstrap-icons";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { useFetch } from "../../features/Fetch/Fetch";
import FileResizer from "react-image-file-resizer";
import Description from "../Inputs/Description";
import Divider from "../Inputs/Divider";
import ModalHeader from "./ModalHeader";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import { Features } from "../../constants";
import { useSelector } from "react-redux";

const EditReceiptEmail = (props: any) => {
	let receiptEmail = props.ReceiptEmail as ReceiptEmailItem;
	let [receipts, setReceipts] = useState((receiptEmail.receiptEmailId > 0 ? [receiptEmail] : []) as ReceiptEmailItem[]);
	let [overlay, setOverlay] = useState(false);
	let request = useFetch();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	useEffect(() => {
		if (receiptEmail.receiptEmailId > 0) {
			request
				.get(`ReceiptEmails/Receipt/${receiptEmail.receiptEmailId}`)
				.then((receipt: ReceiptEmailItem) => {
					let item = {
						...receiptEmail,
						receipts: receipt.receipts,
					};
					setReceipts([{
						...item
					}]);
				});
		}
	}, []);


	return (<>
		<ModalHeader Title={`${receiptEmail.receiptEmailId > 0 ? 'Edit' : 'Add'} Quick Receipt${receiptEmail.receiptEmailId > 0 ? '' : 's'}`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(receiptEmail)} />
		<div className="modal-body mt-5">
			<Grid container>
				{receiptEmail.receiptEmailId === 0 &&
					<Grid item xs={12}>
						<Button style={{ position: 'fixed', right: "5%", zIndex: 1500, marginTop: -40 }} component="label" variant="contained" startIcon={<CloudUploadFill />}>
							Add Images
							<VisuallyHiddenInput type="file" multiple accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" onChange={UploadFile} />
						</Button>
					</Grid>
				}
				{receiptEmail.receiptEmailId > 0 &&
					(receipts[0].receipts ? <></> :
					<>
						Retrieving Receipt...
						<Skeleton variant='rounded' width='100%' height={600} />
					</>)}
				{receipts.map(receipt => {
					var rowNum = receipts.indexOf(receipt);
					return receipt.receipts ?
						<div className="col-12 centered">
							{receipt.receipts.map(rec =>
								<InnerImageZoom src={`data:image/bmp;base64,${rec}`} className="img-zoom mb-3" zoomType="hover" height={600} hideHint={true} zoomScale={1} />
							)}
							<Description Value={receipt.emailSubject} OnChange={(val: any) => UpdateDetails(val.target.value, rowNum)} Size={12} />
							<Divider />
						</div> : <></>
				})}
				{receiptEmail.receiptEmailId > 0 && receipts[0].receipts &&
					<Grid container>
						<Button className="mx-3 mb-3 right" component="label" variant="contained" onClick={New}>
							Create New {FamilyFeatureValue(user, Features.TransactionTitle)}
						</Button>
						<Button className="mx-3 mb-3 right" component="label" variant="contained" onClick={Attach}>
							Attach to Existing {FamilyFeatureValue(user, Features.TransactionTitle)}
						</Button>
					</Grid>
				}
			</Grid>
		</div >
		<ModalFooterSaveCancel ShowSave={receipts.length > 0} Save={() => props.OnSave(receipts)} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
    </>);

	function New() {
		props.CreateNew(receipts[0]);
	}

	function Attach() {
		props.AttachReceipt(receipts[0]);
	}

	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		setOverlay(true)
		let { files } = event.target;
		if (!files) return;

		let realFiles = files as FileList;
		let newReceipts = [] as ReceiptEmailItem[];
		for (let i = 0; i < realFiles.length; i++) {
			let file = realFiles[i];
			let resizedFile = await new Promise(resolve =>
				FileResizer.imageFileResizer(file, 1280, 1280, file.type.replace("image/", ""), 100, 0, uri => resolve(uri))
			);
			let item = {
				...receiptEmail,
				receipts: [ resizedFile!.toString().substring(resizedFile!.toString().indexOf(',') + 1) ]
			};
			newReceipts.push(item);
		}
		let items = [
			...receipts,
			...newReceipts
		];
		setReceipts(items);
		setOverlay(false);
	}

	function UpdateDetails(newValue: string, index: number) {
		let recs = [] as ReceiptEmailItem[];
		for (let i = 0; i < receipts.length; i++) {
			if (i === index) {
				recs.push({ ...receipts[i], emailSubject: newValue })
			} else {
				recs.push({ ...receipts[i] });
			}
		}
		setReceipts(recs);
	}
}

export default EditReceiptEmail;