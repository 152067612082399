import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUser } from "../../types";

interface UserDataState {
	value: CurrentUser
}

const initialState: UserDataState = {
	value: {} as CurrentUser
};

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
	reducers: {
		set: (state = initialState, action: PayloadAction<CurrentUser>) => {
            state.value = action.payload;
        },
        remove: (state = initialState) => {
			state.value = {} as CurrentUser;
		},
		updateUserData: (state = initialState, action: PayloadAction<CurrentUser>) => {
            state.value = {...action.payload};
        }
    }
});

export const { set, remove, updateUserData } = userDataSlice.actions;

export default userDataSlice.reducer;