import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrentUser } from "../../types";
import { Storage } from '../Saving/Storage';
import { set } from "../Slices/userDataSlice";
import { useFetch } from "./Fetch";
import { useItems } from "./Items";

export const useAuth = () => {
	let dispatch = useDispatch();
	let request = useFetch();
	let navigate = useNavigate();
	let items = useItems();

	async function login(credentials: any) {
		let result = await request.post('api/security/login', credentials)
		.then((data: any) => {
			if (data.error) {
				return data.error;
			} else {
				Storage.SaveUserData(data);
				dispatch(set(data));
				window.location.reload();
			}
		});
		return result;
	};

	async function register(credentials: any) {
		let result = await request.post('api/security/register', credentials)
		.then((data: any) => {
			if (data.error) {
				return data.error;
			} else {
				Storage.SaveUserData(data);
				dispatch(set(data));
				window.location.reload();
			}
		});
		return result;
	};

	async function forgotPassword(email: string) {
		let result = await request.post(`api/security/ForgotPassword?email=${email}`, email)
		.then((data: any) => {
			if (data.error) {
				return data.error;
			}
		});
		return result;
	};

	async function resetPassword(credentials: any) {
		let result = await request.post('api/security/ResetPassword', credentials)
		.then((data: any) => {
			if (data.error) {
				return data.error;
			} else {
				Storage.SaveUserData(data);
				dispatch(set(data));
				navigate('/');
			}
		});
		return result;
	};

	async function resendEmailVerification(email: string) {
		let result = await request.get(`api/Security/ResendVerification/${email}`)
			.then((data: any) => {
				if (data.error) {
					return data.error;
				}
			});
		return result;
	};

	async function confirmEmail(credentials: any) {
		let result = await request.post('api/security/ConfirmEmail', credentials)
			.then((data: any) => {
				if (data.error) {
					return data.error;
				} else {
					return null;
				}
			});
		return result;
	};

	async function rejectEmail(credentials: any) {
		let result = await request.post('api/security/RejectEmail', credentials)
			.then((data: any) => {
				if (data.error) {
					return data.error;
				} else {
					return null;
				}
			});
		return result;
	};

	async function setUserContext() {
		let userContext = await request.get('api/security/CurrentUser')
			.then((data: any) => {
				if (data.error || (data.length && data.length === 0)) {
					logout();
					return data.error;
				} else {
					Storage.SaveUserData({ ...data });
					dispatch(set(data));
				}
			});
		return userContext;
	}

	async function changeFamilyContext(familyId: number) {
		let userContext = await request.get('api/security/ChangeFamilyContext/' + familyId)
			.then((data: any) => {
				if (data.error || (data.length && data.length === 0)) {
					logout();
					return data.error;
				} else {
					Storage.SaveUserData({ ...data });
					dispatch(set(data));
				}
			});
		return userContext;
	}

	async function updateUserContext(user: CurrentUser) {
		var item: CurrentUser = { mileageRate: user.mileageRate, unassignedBudgets: user.unassignedBudgets, includeAllUsersInBudgets: user.includeAllUsersInBudgets, familyName: user.familyName };
		var result = request.put("Settings", item)
			.then((data: any) => {
				if (data.error) {
					return data.error;
				} else {
					Storage.SaveUserData({ ...data, mileageRate: user.mileageRate, unassignedBudgets: user.unassignedBudgets });
				}
			});
		return result;
	}

	async function logout() {
		Storage.SaveUserData({} as CurrentUser);
		//Storage.ClearStorage();
		items.ClearData();
		dispatch(set({} as CurrentUser));
	}

	return {
		login,
		register,
		forgotPassword,
		resetPassword,
		resendEmailVerification,
		confirmEmail,
		rejectEmail,
		setUserContext,
		changeFamilyContext,
		updateUserContext,
		logout
	};
}