import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountItem } from "../../types";

interface AccountState {
    value: AccountItem[]
}

const initialState: AccountState = {
    value: []
};

export const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setAccounts: (state = initialState, action: PayloadAction<AccountItem[]>) => {
            state.value = action.payload;
        },
        addAccount: (state = initialState, action: PayloadAction<AccountItem>) => {
            state.value = [
                ...state.value, action.payload
            ];
        },
        removeAccount: (state = initialState, action: PayloadAction<number>) => {
            state.value = [
                ...state.value.filter((item: AccountItem) => {
                    return item.accountId !== action.payload;
                })
            ];
        }
    }
});

export const { setAccounts, addAccount, removeAccount } = accountSlice.actions;

export default accountSlice.reducer;