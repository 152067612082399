import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReceiptEmailItem } from "../../types";

interface ReceiptEmailState {
	value: ReceiptEmailItem[] | undefined
}

const initialState: ReceiptEmailState = {
	value: undefined
};

export const receiptEmailSlice = createSlice({
    name: 'receiptEmails',
    initialState,
    reducers: {
        setReceiptEmails: (state = initialState, action: PayloadAction<ReceiptEmailItem[] | undefined>) => {
            let value: ReceiptEmailItem[] = action?.payload ?? [];
            state.value = value;
        },
		addReceiptEmail: (state = initialState, action: PayloadAction<ReceiptEmailItem>) => {
            let value: ReceiptEmailItem[] = state.value ?? [];
            let newItem = action.payload as ReceiptEmailItem;
            state.value = [
                ...value, newItem
            ];
			return state;
        },
        removeReceiptEmail: (state = initialState, action: PayloadAction<number>) => {
			let value: ReceiptEmailItem[] = state.value ?? [];
			state.value = [
				...value.filter((item: ReceiptEmailItem) => {
					return item.receiptEmailId !== action.payload;
                })
            ];
			return state;
        }
    }
});

export const { setReceiptEmails, addReceiptEmail, removeReceiptEmail } = receiptEmailSlice.actions;

export default receiptEmailSlice.reducer;