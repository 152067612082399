import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BudgetItem, BudgetVariant, DateTime } from "../../types";

interface BudgetState {
	value: BudgetItem[] | undefined
}

const initialState: BudgetState = {
    value: []
};

export const budgetSlice = createSlice({
    name: 'budgets',
    initialState,
    reducers: {
        set: (state = initialState, action: PayloadAction<BudgetItem[] | undefined>) => {
            let value: BudgetItem[] = action?.payload ?? [];
            for (let i = 0; i < value.length; i++) {
                value[i] = {
                    ...value[i],
					variant: {
						amount: value[i].variant.amount,
						budgetId: value[i].variant.budgetId,
						budgetVariantId: value[i].variant.budgetVariantId,
						endDate: value[i].variant.endDate === null ? null : new DateTime(value[i].variant.endDate),
						isDefault: value[i].variant.isDefault,
						isInactive: value[i].variant.isInactive,
						timeframe: value[i].variant.timeframe,
						startDate: new DateTime(value[i].variant.startDate)
					} as BudgetVariant,
					endDate: new DateTime(value[i].endDate)
                }
            }
            state.value = value;
        },
        add: (state = initialState, action: PayloadAction<BudgetItem>) => {
            let value: BudgetItem[] = state.value ?? [];
            let newItem = action.payload as BudgetItem;
            newItem = {
                ...newItem,
				variant: {
					amount: newItem.variant.amount,
					budgetId: newItem.variant.budgetId,
					budgetVariantId: newItem.variant.budgetVariantId,
					endDate: newItem.variant.endDate === null ? null : new DateTime(newItem.variant.endDate),
					isDefault: newItem.variant.isDefault,
					isInactive: newItem.variant.isInactive,
					timeframe: newItem.variant.timeframe,
					startDate: new DateTime(newItem.variant.startDate)
				} as BudgetVariant,
				endDate: new DateTime(newItem.endDate)
            };
            state.value = [
                ...value, newItem
            ];
			return state;
        },
        remove: (state = initialState, action: PayloadAction<number>) => {
			let value: BudgetItem[] = state.value ?? [];
			state.value = [
                ...value.filter((item: BudgetItem) => {
                    return item.budgetId !== action.payload;
                })
            ];
			return state;
        }
    }
});

export const { set, add, remove } = budgetSlice.actions;

export default budgetSlice.reducer;