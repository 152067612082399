import { Autocomplete, Grid, TextField } from "@mui/material";
import { useItems } from "../../features/Fetch/Items";
import { SelectableItem } from "../../types";

const AutoCompleteCategories = (props: any) => {
	let repo = useItems();
	let options: SelectableItem[] = props.Options;
	let opts = options.map(o => o.Id);
    let classes = `mb-3 ${props.Class}`;
    let change = ((e: any) => props.OnChange(e));
    if (props.Index) {
        change = ((e: any) => props.OnChange(e, props.Index));
    }

    return (
        <Grid item xs={props.Size}>
			<Autocomplete
				multiple
				id="tags-outlined"
				options={opts}
				getOptionLabel={GetValueFromId}
				defaultValue={props.Value}
				filterSelectedOptions
				className={classes}
				freeSolo
				renderInput={(params) => (
					<TextField
						{...params}
						label="Categories"
					/>
				)}
				onChange={OnChange}
			/>
        </Grid>
    );

	function GetValueFromId(id: any) {
		return options.find(o => o.Id === id)?.Value ?? id;
	}

	async function OnChange(event: any, newValue: any[]) {
		let str: string[] = newValue.filter((v: any) => typeof v === 'string');
		if (str) {
			newValue = [...newValue.filter((v: any) => typeof v !== 'string')];
			await str.forEach(async s => {
				let existing = options.find(o => o.Value.trim().toLowerCase() === s.trim().toLowerCase());
				if (existing) {
					newValue.push(existing.Id);
				} else {
					let newItem = await repo.UpdateCategoryAsync({ categoryId: 0, description: s, isViewTransactions: true }, true);
					newValue.push(newItem.categoryId);
				}
			})
		}
		change(newValue);
	}
};

export default AutoCompleteCategories;