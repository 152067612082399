import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material';
import Sidebar from './Sidebar';
import { CurrentUser, TransactionItem } from '../../types';
import { useSelector } from 'react-redux';

const NavMenu = (props: any) => {
	const [state, setState] = useState({ collapsed: true });

	let currentUser: CurrentUser = useSelector((state: any) => state.userData.value);
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);

	const isHoH = currentUser.isActive && currentUser.isHoH;
	let needsApproval = transactions?.filter(t => t.needsMyApproval).length > 0 || checkRequests?.filter(c => c.needsMyApproval || c.checkRequest!.needsMyApproval).length > 0;
	let hasRejections = transactions?.filter(i => i.isMine && i.budgetCategoryAmounts.find(b => b.isRejected)).length > 0;
	let hasNeedsReview = isHoH && transactions?.filter(t => t.isAdminReview).length > 0;

	return (
		<>
			<AppBar position="static">
				<Toolbar className="w-100 topbar">
					<header className="top-bar">
						<div className='d-flex align-items-center brandbar'>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="end"
								onClick={ToggleNavbar}
								className={needsApproval || hasRejections || hasNeedsReview ? "highlighted" : ""}>
								<Icon.List size="35" />
							</IconButton>
							<div className="flex-grow-1"></div>
							<h2 style={{ display: 'inline-flex', marginBottom: 0, overflow: 'clip', whiteSpace: 'nowrap' }}>{currentUser.familyName} Family</h2>
							<div className="flex-grow-1"></div>
							<IconButton style={{ visibility: 'hidden' }} >
								<Icon.List size="25" />
							</IconButton>
						</div>
					</header>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { sm: 300 },
					flexShrink: { sm: 0 }
				}}>
				<Drawer
					variant="temporary"
					open={!state.collapsed}
					onClose={ToggleNavbar}
					ModalProps={{ keepMounted: true }}
					sx={{
						display: { xs: "block", lg: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: 300 }
					}}>
					<Sidebar PassedIn IsHoH={isHoH} NeedsApproval={needsApproval} HasRejections={hasRejections} HasNeedsReview={hasNeedsReview} GetData={props.GetData} OnClick={ToggleNavbar} />
				</Drawer>
			</Box>
		</>
	);

	function ToggleNavbar() {
		setState({
			collapsed: !state.collapsed
		});
	}
}

export default NavMenu;