import { Grid } from "@mui/material";
import { USCurrency } from "../../types";

const Unbudget = (props: any) => {
	return (
		<Grid container className={`btn-list border-app btn-budget bg-white text-secondary`} onClick={props.OnClick}>
			<Grid item xs={5}>
				<h3>{USCurrency(props.Total)} Unbudgeted</h3>
			</Grid>
			<Grid item xs={7} className="right">
			</Grid>
			<Grid item xs={12}>
				<>Since {props.Since.toString()}</>
            </Grid>
			<Grid item xs={12} className="right">
			</Grid>
			<Grid item xs={4}>
			</Grid>
			<Grid item xs={8} className={`right`}>
            </Grid>
		</Grid >
    );
}

export default Unbudget;