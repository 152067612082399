import Date from "../Inputs/Date";
import { Backdrop, Button, CircularProgress, Grid, Paper, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { BudgetItem, BudgetTransaction, BudgetTransactionTotal, CategoryItem, CustomReportFilter, CustomReportSettings, DateTime, GroupBy, Grouping, GroupTransactionTotal, StyledTableCell, StyledTableRow, TransactionItem, USCurrency } from "../../types";
import { useState } from "react";
import ModalHeader from "./ModalHeader";
import ModalFooterCustom from "./ModalFooterCustom";
import { DownloadPdf } from "../../features/ReportService";
import { useItems } from "../../features/Fetch/Items";

const ExpensesReport = (props: any) => {
	let budgets: BudgetItem[] = props.Budgets;
	let categories: CategoryItem[] = props.Categories;
	let transactions: TransactionItem[] = props.Transactions;

	let [startDate, setStartDate] = useState(props.StartDate as DateTime);
	let [endDate, setEndDate] = useState(props.EndDate as DateTime);
	let [overlay, setOverlay] = useState(false);
	let budgetGroups: Grouping[] = GetBudgetGroups();

	let repo = useItems();

	return (<>
		<ModalHeader Title="All Budgets" CanDelete={false} Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Button onClick={() => Export(budgetGroups)}>
						Download Report
					</Button>
				</Grid>
				<Date Size={4} Label="From" Value={startDate} OnChange={(v: any) => setStartDate(new DateTime(v))} />
				<Date Size={4} Label="To" Value={endDate} OnChange={(v: any) => setEndDate(new DateTime(v))} />
				<Grid item xs={4} >
					<h1 className="report-total">{USCurrency(GroupTransactionTotal(budgetGroups))}</h1>
				</Grid>
			</Grid>

			{budgetGroups.map((group: Grouping) => <>
				<h5>{group.Key.description} - {USCurrency(BudgetTransactionTotal(group.Value))}</h5>
				<TableContainer component={Paper} className="report">
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell>Date</StyledTableCell>
								<StyledTableCell>Name</StyledTableCell>
								<StyledTableCell>Merchant</StyledTableCell>
								<StyledTableCell>Category</StyledTableCell>
								<StyledTableCell>Description</StyledTableCell>
								<StyledTableCell>Amount</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{group.Value.map((item: BudgetTransaction) =>
								<StyledTableRow key={item.Transaction.accountTransactionId}>
									<StyledTableCell>{item.Transaction.transactionDate.toString()}</StyledTableCell>
									<StyledTableCell>{item.Transaction.created}</StyledTableCell>
									<StyledTableCell>{item.Transaction.merchant}</StyledTableCell>
									<StyledTableCell>{item.Category?.description}</StyledTableCell>
									<StyledTableCell>{item.Amount.details}</StyledTableCell>
									<StyledTableCell>{USCurrency(item.Amount.amount)}{!item.Transaction.hasReceipt ? '*' : ''}</StyledTableCell>
								</StyledTableRow>
							)}
						</TableBody>
						<TableFooter>
							<StyledTableRow>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell><b>TOTAL</b></StyledTableCell>
								<StyledTableCell><b>{USCurrency(BudgetTransactionTotal(group.Value))}</b></StyledTableCell>
							</StyledTableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</>)}
		</div>
		<ModalFooterCustom HideSave={true} Close={props.Close} />
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
			<CircularProgress />
		</Backdrop>
	</>);

	function GetBudgetGroups() {
		var transacts = transactions.filter(t => t.transactionDate >= startDate.startOfDay() && t.transactionDate <= endDate.startOfDay() && (t.rate != null && t.rate > 0) === props.IsMileage);
		let budgetTransactions = [] as BudgetTransaction[];
		transacts.forEach(transaction => {
			transaction.budgetCategoryAmounts.forEach(amount => {
				budgetTransactions.push({
					Budget: budgets.find(b => b.budgetId === amount.budgetId) as BudgetItem,
					Category: categories.find(c => c.categoryId === amount.categoryId),
					Transaction: transaction,
					Amount: amount
				});
			});
		});
		budgetTransactions = budgetTransactions
			.sort((a, b) => a.Transaction.transactionDate.diff(b.Transaction.transactionDate) ||
				a.Budget.description.localeCompare(b.Budget.description) ||
				a.Transaction.created.localeCompare(b.Transaction.created) ||
				(a.Category?.description.localeCompare(b.Category?.description ?? '') ?? 1));

		return GroupBy(budgetTransactions, "Budget");
	}

	async function Export(items: Grouping[]) {
		setOverlay(true);
		setTimeout(() => GenerateReport(items), 50);
	}

	async function GenerateReport(items: Grouping[]) {
		let settings: CustomReportSettings = {
			title: `${!props.IsMileage ? "Spending" : "Mileage"} By Budget`,
			columns: ['Date', 'Name', 'Merchant', 'Category', 'Description', 'Amount'],
			grouping: 'Budget',
			filter: {
				startDate: startDate.Value,
				endDate: endDate.Value,
				includeSpending: !props.IsMileage,
				includeMiles: props.IsMileage,
				includeCheckRequests: false,
				combineSplitBudgets: false,
				includeReceipts: false,
				groupReceipts: false
			} as CustomReportFilter
		}
		let file = await repo.GetReportPdfAsync(settings);
		DownloadPdf(await file.blob() as Blob, `${!props.IsMileage ? "Spending" : "Mileage"} By Budget`);
		setOverlay(false);
	}
}

export default ExpensesReport;