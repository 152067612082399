import { useState } from "react";

import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useAuth } from "../../features/Fetch/Auth";
import Error from "../../components/Error";
import PasswordInput from "../../components/Inputs/PasswordInput";
import logo from '../../images/logo2.png'
import { useLocation } from "react-router-dom";

let initialStateVars = {
	Email: '',
	Token: '',
	Password: '',
	ConfirmPassword: ''
};

const Reset = (props: any) => {
	let auth = useAuth();
	let location = useLocation();
	let params = new URLSearchParams(location.search);

	let model = {
		...initialStateVars,
		Email: params.get('email'),
		Token: params.get('token')
	};

    let [stateVars, setStateVars] = useState(model);
	let [error, setError] = useState('');
	let [overlay, setOverlay] = useState(false);

	let [hasLength, setHasLength] = useState(false);
	let [hasLower, setHasLower] = useState(false);
	let [hasUpper, setHasUpper] = useState(false);
	let [hasNumber, setHasNumber] = useState(false);
	let [hasSpecial, setHasSpecial] = useState(false);
	let isValidPassword = hasLength && hasLower && hasUpper && hasNumber && hasSpecial;

    return (
        <div className="login page">
			<div className="form-edit centered">
				<div className="centered mt-20 text-success">
					<h5>Reset Password for</h5>
				</div>
				<img className="img-title" src={logo} alt="Shekl" />
				<Grid container rowSpacing={3} justifyContent="center">
					<Grid item xs={8}>
						<Error Text={error} />
					</Grid>
					<Grid item xs={7} textAlign="left">
						<div>Password must have the following:</div>
						<div style={{ color: hasLength ? "green" : "orange" }}>8 or more characters</div>
						<div style={{ color: hasLower ? "green" : "orange" }}>Lowercare letter</div>
						<div style={{ color: hasUpper ? "green" : "orange" }}>Capital letter</div>
						<div style={{ color: hasNumber ? "green" : "orange" }}>Number</div>
						<div style={{ color: hasSpecial ? "green" : "orange" }}>Special character</div>
					</Grid>
					<PasswordInput Id="inputPassword" Label="Password" Value={stateVars.Password} Size={8} OnSubmit="@OnSubmit" OnChange={(e: string) => PasswordChange(e)} />
					<PasswordInput Id="confirmPassword" Label="Confirm Password" Value={stateVars.ConfirmPassword} Size={8} OnSubmit="@OnSubmit" OnChange={(e: string) => ConfirmPasswordChange(e)} />
					<Grid item xs={8}>
						<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={OnSubmit}>Update Password</Button>
					</Grid>
				</Grid>
				<Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
					<CircularProgress />
				</Backdrop>
			</div>
        </div>
    );

	function PasswordChange(input: any) {
		let validationRegex = [
			{ regex: /.{8,}/ },
			{ regex: /[0-9]/ },
			{ regex: /[a-z]/ },
			{ regex: /[A-Z]/ },
			{ regex: /[^A-Za-z0-9]/ },
		];

		setHasLength(validationRegex[0].regex.test(input.target.value));
		setHasNumber(validationRegex[1].regex.test(input.target.value));
		setHasLower(validationRegex[2].regex.test(input.target.value));
		setHasUpper(validationRegex[3].regex.test(input.target.value));
		setHasSpecial(validationRegex[4].regex.test(input.target.value));

		setStateVars({
			...stateVars, Password: input.target.value
		});
	}

	function ConfirmPasswordChange(input: any) {
		setStateVars({
			...stateVars, ConfirmPassword: input.target.value
		});
	}

	async function OnSubmit() {
		setOverlay(true);
		if (!String(stateVars.Email).match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			setOverlay(false);
			setError('You must enter a valid email.');
		} else if (!stateVars.Password || !isValidPassword) {
			setOverlay(false);
			setError('Your password must fit the complexity requirements.');
		} else if (stateVars.Password != stateVars.ConfirmPassword) {
			setOverlay(false);
			setError('Your passwords must match.');
		} else {
			let result = await auth.resetPassword({ FirstName: 'password', LastName: 'reset', Code: stateVars.Token, Email: stateVars.Email, Password: stateVars.Password, PasswordConfirm: stateVars.ConfirmPassword });
			setError(result);
			setOverlay(false);
		}
	}
}

export default Reset;