import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import React from "react";
import { SelectableItem } from "../../types";

const Dropdown = (props: any) => {
	let outer: JSX.Element[] = [];
	let options: Map<number, SelectableItem> = props.Options;
    options?.forEach((value, key) => {
        if (props.Multiple) {
            outer.push(
                <MenuItem value={value.Id} key={`opt${key}`}>
                    <Checkbox checked={props.Value.indexOf(value.Id) > -1} />
                    <ListItemText primary={value.Value} />
                </MenuItem>
            );
		} else {
			outer.push(<MenuItem value={value.Id} key={`opt${key}`} disabled={value.Id < -1}>{value.Value}</MenuItem>);
        }
    });
    let classes = `mb-3 ${props.Class}`;
    let change = ((e: any) => props.OnChange(e.target.value));
    if (props.Index) {
        change = ((e: any) => props.OnChange(e.target.value, props.Index));
    }

    return (
        <Grid item xs={props.Size}>
            <FormControl fullWidth className={classes}>
                <InputLabel>{props.Label}</InputLabel>
				<Select
					variant={props.Variant ?? "outlined"}
                    value={props.Value}
                    label={props.Label}
                    multiple={props.Multiple}
                    renderValue={(selected) => props.Multiple ? selected.map((s: number) => GetValueFromId(s)).join(', ') : GetValueFromId(selected)}
                    onChange={change}
                >
                    {outer}
                </Select>
            </FormControl>
        </Grid>
    );

	function GetValueFromId(id: number) {
		return props.Options.find((o: SelectableItem) => o.Id === id)?.Value;
	}
};

export default Dropdown;