import React from 'react';
import { Grid, TextField } from '@mui/material';

const EmailInput = (props: any) => {
	return (
		<Grid item xs={props.Size}>
			<TextField
				id="email"
				name="email"
				autoComplete="email"
				variant='outlined'
				className={`form-control border-app ${props.Class} col-${props.Size}`}
				fullWidth
				label={(props.NoLabel ? "" : props.Label ?? "Email")}
				required={props.Required}
				value={props.Value}
				onKeyUp={props.OnKeyUp}
				onChange={props.OnChange}
				autoFocus />
		</Grid>
	);
}

export default EmailInput;