import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Grid } from "@mui/material";

const Date = (props: any) => {
    return (
        <Grid item xs={props.Size} className="mb-3">
			<DatePicker label={props.Label} value={props.Value === undefined ? undefined : dayjs(props.Value)} className={`form-control${ props.NoError ? ' no-error' : ''}`} onChange={(e) => props.OnChange(e?.toDate())} />
        </Grid>
    );
};

export default Date;