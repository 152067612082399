import { useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import Amount from "../Inputs/Amount";
import Date from "../Inputs/Date";
import { AccountItem, AccountTransactionFilterModel, BudgetItem, CategoryItem, CurrentUser, FamilyFeatureValue, FamilyHasAccess, SelectableItem } from "../../types";
import { Grid } from "@mui/material";
import { Features } from "../../constants";
import { useSelector } from "react-redux";
import Toggle from "../Inputs/Toggle";
import ModalHeader from "./ModalHeader";
import ModalFooterThreeButtons from "./ModalFooterThreeButtons";
import { ArrowClockwise, Filter } from "react-bootstrap-icons";
import { useItems } from "../../features/Fetch/Items";

function EditFilter(props: any) {
	let repo = useItems();

	let [filter, setFilter] = useState(props.Filter as AccountTransactionFilterModel);
	let accounts: AccountItem[] = repo.SortedAccounts();
	let budgets: BudgetItem[] = props.Budgets;
	let categories: CategoryItem[] = props.Categories;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	let useAccounts = FamilyHasAccess(user, Features.Accounts);
	let useBudgets = FamilyHasAccess(user, Features.Budgets);
	let useCategories = FamilyHasAccess(user, Features.Categories);

	let emptyFilter: AccountTransactionFilterModel = {
		AccountId: undefined,
		IsRecurring: false,
		IsNotRecurring: false,
		NeedsReceipt: false,
		NeedsReimbursement: false,
		IsAdminReview: false,
		IsMine: FamilyFeatureValue(user, Features.ShowTransactionsAllUsers) !== 'true',
		IsRejected: false,
		IsIncome: false,
		IsExpense: false,
		Merchant: '',
		User: '',
		StartDate: undefined,
		EndDate: undefined,
		IncludeAccountIds: [],
		IncludeBudgetIds: [],
		ExcludeBudgetIds: budgets?.filter(b => !b.isViewTransactions)?.map(b => b.budgetId) ?? [],
		IncludeCategoryIds: [],
		ExcludeCategoryIds: categories?.filter(c => !c.isViewTransactions)?.map(c => c.categoryId) ?? [],
		MinAmount: undefined,
		MaxAmount: undefined
	};

	return (<>
		<ModalHeader Title="Filter Transactions" CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(filter)} />
		<div className="modal-body mt-3">
			<Grid container spacing={1}>
				<Date Size={6} Label="Earliest Date..." Value={filter.StartDate} OnChange={UpdateStartDate} />
				<Date Size={6} Label="Latest Date..." Value={filter.EndDate} OnChange={UpdateEndDate} />
				<Amount Label="Minimum Amount..." Size={6} Value={filter.MinAmount} OnChange={(val: any) => UpdateMinAmount(val.target.value)} />
				<Amount Label="Maximum Amount..." Size={6} Value={filter.MaxAmount} OnChange={(val: any) => UpdateMaxAmount(val.target.value)} />
				<Description Label="Merchant Contains..." Value={filter.Merchant} OnChange={(val: any) => UpdateMerchant(val.target.value)} Size={12} />
				<Toggle Size={12} OnChange={UpdateIsMine} Checked={filter.IsMine} Label={`Only Show My ${FamilyFeatureValue(user, Features.TransactionTitle) ?? "Transaction"}s`} />
				{!filter.IsMine && <Description Label="Created By..." Value={filter.User} OnChange={(val: any) => UpdateUser(val.target.value)} Size={12} />}
				{useAccounts &&
					<Dropdown Size={12}
						Class="mb-0"
						Label="Include Accounts..."
						Options={accounts
							.map(c => {
								return {
									Id: c.accountId,
									Value: c.description
								} as SelectableItem;
							})}
						Index={0}
						Value={filter.IncludeAccountIds}
						Multiple
						OnChange={UpdateAccounts} />
				}
				{useBudgets &&
					<Dropdown Size={6}
						Class="mb-0"
						Label="Include Budgets..."
						Options={budgets
							.map(c => {
								return {
									Id: c.budgetId,
									Value: c.description
								} as SelectableItem;
							})}
						Index={0}
						Value={filter.IncludeBudgetIds}
						Multiple
						OnChange={UpdateBudgets} />
				}
				{useBudgets &&
					<Dropdown Size={6}
						Class="mb-0"
						Label="Exclude Budgets..."
						Options={budgets
							.map(c => {
								return {
									Id: c.budgetId,
									Value: c.description
								} as SelectableItem;
							})}
						Index={0}
						Value={filter.ExcludeBudgetIds}
						Multiple
						OnChange={UpdateExcludedBudgets} />
				}
				{useCategories &&
					<Dropdown Size={6}
						Class="mb-0"
						Label="Include Categories..."
						Options={categories
							.map(c => {
								return {
									Id: c.categoryId,
									Value: c.description
								} as SelectableItem;
							})}
						Index={0}
						Value={filter.IncludeCategoryIds}
						Multiple
						OnChange={UpdateCategoriesInclude} />
				}
				{useCategories &&
					<Dropdown Size={6}
						Class="mb-0"
						Label="Exclude Categories..."
						Options={categories
							.map(c => {
								return {
									Id: c.categoryId,
									Value: c.description
								} as SelectableItem;
							})}
						Index={0}
						Value={filter.ExcludeCategoryIds}
						Multiple
						OnChange={UpdateCategoriesExclude} />
				}
				<Toggle Size={12} OnChange={UpdateNeedsReceipt} Checked={filter.NeedsReceipt ?? false} Label="Needs Receipt" />
				<Toggle Size={12} OnChange={UpdateNeedsReimbursement} Checked={filter.NeedsReimbursement ?? false} Label="Needs Reimbursement" />
				{FamilyHasAccess(user, Features.Recurring) && <>
					<Toggle Size={12} OnChange={UpdateIsNotRecurring} Checked={filter.IsNotRecurring ?? false} Label="Hide Repeating" />
				</>}
			</Grid>
		</div>
		<ModalFooterThreeButtons EnableFirst={true} FirstClicked={() => props.OnSave(filter)} FirstText="Filter" FirstStartIcon={<Filter />} EnableSecond={!FilterIsDefault()} SecondClicked={ClearFilter} SecondText="Reset" SecondStartIcon={<ArrowClockwise />} Close={props.Close} />
    </>);

	function GetFloatValue(newValue: string) {
		let val: number | undefined = undefined;
		if (newValue) val = parseFloat(newValue);
		return val;
	}
	function UpdateMerchant(newValue: string) {
		var item = ({ ...filter, Merchant: newValue });
		setFilter(item);
	}
	function UpdateUser(newValue: string) {
		var item = ({ ...filter, User: newValue });
		setFilter(item);
	}
	function UpdateAccounts(newValue: any) {
		let item = ({ ...filter, IncludeAccountIds: newValue });
		setFilter(item);
	}
	function UpdateBudgets(newValue: any) {
		let item = ({ ...filter, IncludeBudgetIds: newValue });
		setFilter(item);
	}
	function UpdateExcludedBudgets(newValue: any) {
		let item = ({ ...filter, ExcludeBudgetIds: newValue });
		setFilter(item);
	}
	function UpdateCategoriesInclude(newValue: any) {
		let item = ({ ...filter, IncludeCategoryIds: newValue });
		setFilter(item);
	}
	function UpdateCategoriesExclude(newValue: any) {
		let item = ({ ...filter, ExcludeCategoryIds: newValue });
		setFilter(item);
	}
	function UpdateStartDate(newValue: any) {
		let item = ({ ...filter, StartDate: newValue });
		setFilter(item);
	}
	function UpdateEndDate(newValue: any) {
		let item = ({ ...filter, EndDate: newValue });
		setFilter(item);
	}
	function UpdateMinAmount(newValue: string) {
		let item = ({ ...filter, MinAmount: GetFloatValue(newValue) });
		setFilter(item);
	}
	function UpdateMaxAmount(newValue: string) {
		let item = ({ ...filter, MaxAmount: GetFloatValue(newValue) });
		setFilter(item);
	}
	function UpdateIsMine(_: any, newValue: boolean) {
		let item = ({ ...filter, IsMine: newValue });
		setFilter(item);
	}
	function UpdateNeedsReceipt(_: any, newValue: boolean) {
		let item = ({ ...filter, NeedsReceipt: newValue });
		setFilter(item);
	}
	function UpdateNeedsReimbursement(_: any, newValue: boolean) {
		let item = ({ ...filter, NeedsReimbursement: newValue });
		setFilter(item);
	}
	function UpdateIsNotRecurring(_: any, newValue: boolean) {
		let item = ({ ...filter, IsNotRecurring: newValue });
		setFilter(item);
	}

	function ClearFilter() {
		setFilter({ ...emptyFilter });
		props.OnSave(emptyFilter);
	}

	function FilterIsDefault() {
		return (
			filter.AccountId === emptyFilter.AccountId &&
			filter.IsRecurring === emptyFilter.IsRecurring &&
			filter.IsNotRecurring === emptyFilter.IsNotRecurring &&
			filter.NeedsReceipt === emptyFilter.NeedsReceipt &&
			filter.NeedsReimbursement === emptyFilter.NeedsReimbursement &&
			filter.IsAdminReview === emptyFilter.IsAdminReview &&
			filter.IsMine === emptyFilter.IsMine &&
			filter.IsRejected === emptyFilter.IsRejected &&
			filter.IsIncome === emptyFilter.IsIncome &&
			filter.IsExpense === emptyFilter.IsExpense &&
			filter.Merchant === emptyFilter.Merchant &&
			filter.User === emptyFilter.User &&
			filter.StartDate === emptyFilter.StartDate &&
			filter.EndDate === emptyFilter.EndDate &&
			filter.MinAmount === emptyFilter.MinAmount &&
			filter.MaxAmount === emptyFilter.MaxAmount &&
			filter.IncludeBudgetIds.length === emptyFilter.IncludeBudgetIds.length &&
			filter.ExcludeBudgetIds.length === emptyFilter.ExcludeBudgetIds.length &&
			filter.IncludeCategoryIds.length === emptyFilter.IncludeCategoryIds.length &&
			filter.ExcludeCategoryIds.length === emptyFilter.ExcludeCategoryIds.length &&
			filter.IncludeBudgetIds.filter(i => !emptyFilter.IncludeBudgetIds.includes(i)).length === 0 &&
			filter.ExcludeBudgetIds.filter(i => !emptyFilter.ExcludeBudgetIds.includes(i)).length === 0 &&
			filter.IncludeCategoryIds.filter(i => !emptyFilter.IncludeCategoryIds.includes(i)).length === 0 &&
			filter.ExcludeCategoryIds.filter(i => !emptyFilter.ExcludeCategoryIds.includes(i)).length === 0
		);
	}
}

export default EditFilter;