import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IsMobileState {
    value: boolean
}

const initialState: IsMobileState = {
	value: true
};

export const userDataSlice = createSlice({
    name: 'isMobile',
    initialState,
	reducers: {
		setIsMobile: (state = initialState, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        }
    }
});

export const { setIsMobile } = userDataSlice.actions;

export default userDataSlice.reducer;