import { Grid } from "@mui/material";
import { FamilyCode } from "../../types";

const Code = (props: any) => {
	let item: FamilyCode = props.Item;

	return (
		<Grid container className="btn-list text-app" onClick={props.OnClick}>
			<Grid item xs={8} >
				<h3>{item.code}</h3>
			</Grid>
			<Grid item xs={4} className="right">
				{item.isAdmin ? "Full" : "Limited"}
			</Grid>
		</Grid >
    );
}

export default Code;