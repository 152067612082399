import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

const DropdownMenu = (props: any) => {
	let [menuOpen, setMenuOpen] = useState(false);
	let [anchor, setAnchor] = useState<null | HTMLElement>(null);
	let idx = 0;

	return (<>
		<div className="d-inline" onClick={OpenMenu}>
			{props.OpenElement}
		</div>
		<Menu
			anchorEl={anchor}
			open={menuOpen || props.Open === true}
			onClose={CloseMenu}
			closeAfterTransition={true}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
			{props.Items.map((item: any) => {
				let rowNum = idx++;
				if (item.Info) {
					return <div className='w-100 centered mb-3 text-app' key={`ddm${rowNum}`}>
						{item.InfoText}
					</div>;
				} else {
					return <MenuItem onClick={() => OnClick(rowNum)} key={`ddm${rowNum}`}>
						{item.Icon &&
							<ListItemIcon className="text-app" key={`ddmi${rowNum}`}>
								{item.Icon}
							</ListItemIcon>
						}
						{item.Text}
					</MenuItem>
				}
			})}
		</Menu>
    </>);

	function OpenMenu(event: React.MouseEvent<HTMLElement>) {
		setMenuOpen(true);
		setAnchor(event.currentTarget);
		if (props.OnOpen) {
			props.OnOpen();
		}
	}
	function CloseMenu() {
		setMenuOpen(false);
		setAnchor(null);
		if (props.OnClose) {
			props.OnClose();
		}
	}

	function OnClick(index: number) {
		if (!props.KeepOpen) {
			setMenuOpen(false);
			setAnchor(null);
		}
		props.Items[index].OnClick();
	}
};

export default DropdownMenu;