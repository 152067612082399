import { useState } from "react";

import { Button, Grid } from "@mui/material";
import Error from "../components/Error";
import TextInput from "../components/Inputs/TextInput";
import { FamilyCode } from "../types";
import { useFetch } from "../features/Fetch/Fetch";

let initialStateVars: FamilyCode = {
	familyCodeId: 0,
	code: '',
	isAdmin: false
};

const AddFamily = (props: any) => {
	let fetch = useFetch();

	let [stateVars, setStateVars] = useState(initialStateVars);
	let [error, setError] = useState('');

	return (
		<div className="login page">
			<div className="form-edit centered">
				<div className="centered mt-20 text-success">
				</div>
				<Grid container rowSpacing={3} columnSpacing={1} justifyContent="center">
					<Grid item xs={9}>
						<h4>Link to an Existing Family</h4>
					</Grid>
					<TextInput Required Id="inputCode" Label="Enter a new Family Code" Value={stateVars.code} Size={4} OnChange={(e: string) => CodeChange(e)} />
					<Grid item xs={4}>
						<Button variant="contained" className="centered col-12 bg-success h-100" onClick={OnSubmit}>Submit</Button>
					</Grid>
					<Grid item xs={8}>
						<Error Text={error} />
					</Grid>
				</Grid>
			</div>
		</div>
	);

	function CodeChange(input: any) {
		setStateVars({
			...stateVars, code: input.target.value
		});
	}

	async function OnSubmit() {
		try {
			await fetch.get(`User/Codes/Link/${stateVars.code}`);
			CodeChange('');
		} catch { }
		setError("If your code was valid, the family manager should be able to activate your account.");
	}
}

export default AddFamily;