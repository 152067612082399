import { useEffect, useState } from "react";
import Description from "../Inputs/Description";
import { BudgetCategoryAmount, BudgetItem, CategoryItem, CurrentUser, FamilyFeatureValue, TransactionItem, USCurrency, UserItem } from "../../types";
import { Backdrop, Button, CircularProgress, Grid, Skeleton } from "@mui/material";
import Divider from "../Inputs/Divider";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";
import { Features } from "../../constants";
import { useSelector } from "react-redux";
import { useFetch } from "../../features/Fetch/Fetch";
import InnerImageZoom from "react-inner-image-zoom";

const ApproveTransaction = (props: any) => {
	let [transaction, setTransaction] = useState(props.Transaction as TransactionItem);
	let [fetching, setFetching] = useState(true);
	let budgets: BudgetItem[] = props.Budgets;
	let categories: CategoryItem[] = props.Categories;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let request = useFetch();

	useEffect(() => {
		if (transaction.accountTransactionId > 0) {
			request
				.get(`Transactions/Receipt/${transaction.accountTransactionId}`)
				.then((receipt: TransactionItem) => {
					let item = {
						...transaction,
						receipts: receipt.receipts,
					};
					setTransaction({
						...item
					});
					setFetching(false);
				});
		} else {
			setFetching(false);
		}
	}, []);

	let total: number | null = transaction.budgetCategoryAmounts.reduce((total, t) => total + t.amount, 0);
	if (total === 0) total = null;

	return (<>
		<ModalHeader Title={`Approve ${FamilyFeatureValue(user, Features.TransactionTitle)}`} CanDelete={false} Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container>
				{(fetching) ?
					<>
						Retrieving Invoice...
						<Skeleton variant='rounded' width='100%' height={600} />
					</> :
					<></>}
				{transaction.receipts.map((receipt: any) => {
					var rowNum = transaction.receipts.indexOf(receipt);
					return (
						<div className="col-12 centered">
							<InnerImageZoom src={`data:image/bmp;base64,${receipt}`} className="img-zoom mb-3" zoomType="hover" height={600} hideHint={true} zoomScale={1.5} />
							<Divider />
						</div>
					);
				})}
				<Grid item xs={12} className="mb-1">Entered by {transaction.created}</Grid>
				{props.Mileage ? (
					<Grid item xs={12} className="mb-1">{transaction.merchant}</Grid>
				) : (
					<Grid item xs={12} className="mb-1">Merchant: {transaction.merchant}</Grid>
				)}
				<Grid item xs={12} className="mb-1">Date: {transaction.transactionDate.toString()}</Grid>
				{transaction.budgetCategoryAmounts.map(budget => {
					if (budget.reviewerId !== 0 && users.find(r => r.isMe)!.userAccountId !== budget.reviewerId) {
						return (<></>);
					}
					var rowNum = transaction.budgetCategoryAmounts.indexOf(budget);
					return (<>
						<Divider />
						<Grid item xs={6} className="mb-1">Budget: {budgets.find(b => b.budgetId === budget.budgetId)?.description}</Grid>
						<Grid item xs={6} className="mb-1 px-3" style={{ textAlign: "right" }}>Category: {categories.find(c => c.categoryId === budget.categoryId)?.description}</Grid>
						<Grid item xs={12} className="mb-1">Amount: {USCurrency(budget.amount)}</Grid>
						{budget.details && budget.details.trim().length > 0 &&
							<Grid item xs={12} className="mt-3">Additonal Information: {budget.details}</Grid>
						}
						<Grid item xs={12} className="centered mt-3 mb-2">
							<Button variant={budget.isRejected ? "contained" : "outlined"} onClick={() => UpdateApproved(rowNum, false)}>Reject</Button>
							<Button variant={budget.isApproved ? "contained" : "outlined"} onClick={() => UpdateApproved(rowNum, true)}>Approve</Button>
						</Grid>
						<Description Size={12} Label="Note" Value={budget.approvalNote} OnChange={(val: any) => UpdateDetails(rowNum, val.target.value)} />
					</>);
				})}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={true} Save={() => props.OnSave(transaction)} Close={props.Close} />
	</>);

	function UpdateDetails(index: number, newValue: string) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, approvalNote: newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
	function UpdateApproved(index: number, newValue: boolean) {
		if (!index) index = 0;
		let bca: BudgetCategoryAmount[] = [];
		transaction.budgetCategoryAmounts.forEach(budget => {
			if (transaction.budgetCategoryAmounts.indexOf(budget) === index) {
				bca.push({ ...budget, isApproved: newValue, isRejected: !newValue });
			} else {
				bca.push({ ...budget });
			}
		})
		let item = ({ ...transaction, budgetCategoryAmounts: bca });
		setTransaction(item);
	}
}
export default ApproveTransaction;