import { Button, Grid } from "@mui/material";

const ModalFooterThreeButtons = (props: any) => {

	return (
		<Grid container className="modal-footer">
			<Grid item xs={4} className="px-1">
				{!props.HideFirst &&
					<Button fullWidth variant="contained" onClick={props.FirstClicked} startIcon={props.FirstStartIcon} disabled={!props.EnableFirst} className={`${props.EnableFirst ? 'bg-success' : 'bg-disabled'} text-white btn-list`}>{props.FirstText ?? "Save"}</Button>
				}
			</Grid>
			<Grid item xs={4} className="px-1">
				{!props.HideSecond &&
					<Button fullWidth variant="contained" onClick={props.SecondClicked} startIcon={props.SecondStartIcon} disabled={!props.EnableSecond} className={`${props.EnableSecond ? 'bg-app' : 'bg-disabled'} text-white btn-list`}>{props.SecondText ?? "Save"}</Button>
				}
			</Grid>
			<Grid item xs={4} className="px-1">
				<Button fullWidth variant="outlined" onClick={props.Close} className="border-success text-success btn-list">Cancel</Button>
			</Grid>
		</Grid>
	);
}

export default ModalFooterThreeButtons;