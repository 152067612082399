import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './styles/App.css';
import Layout from './components/Layout/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Storage } from './features/Saving/Storage';
import { set as setUserData } from './features/Slices/userDataSlice';
import { setIsMobile } from './features/Slices/isMobileSlice';
import { CurrentUser } from './types';
import Login from './pages/Security/Login';
import Inactive from './pages/Inactive';
import Reset from './pages/Security/Reset';
import mapboxgl from 'mapbox-gl';
import ConfirmEmail from './pages/Security/ConfirmEmail';
import RejectEmail from './pages/Security/RejectEmail';

mapboxgl.accessToken = 'pk.eyJ1IjoiZnJlZWxpZmVhcHBzIiwiYSI6ImNsb3B5a2h6dTBjMXcyaXFxNnBubnVqNnUifQ.Ai5E9ZbUBm9o2JvQyVEjzg';

export const connection: string = process.env.REACT_APP_API_BASE_URL!;
export var pageLoad: any = { instance: 1 };
function App() {
	let currentUser: CurrentUser = useSelector((state: any) => state.userData.value);
	let dispatch = useDispatch();
	let location = useLocation();

	useEffect(() => {
		let data = Storage.LoadUserData();
		if (data) {
			if (data.token && data.token !== '') {
				dispatch(setUserData(data));
			}
		}
		dispatch(setIsMobile(isMobile()));
	}, [dispatch]);

	if (location.pathname === "/reset") {
		return (<Reset />);
	} else if (location.pathname === "/confirmemail") {
		return (<ConfirmEmail />);
	} else if (location.pathname === "/rejectemail") {
		return (<RejectEmail />);
	} else if (!currentUser.token) {
		return (<Login />);
	} else if (!currentUser.isActive) {
		return (
			<Layout>
				<Inactive />
			</Layout>
			);
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Layout>
                <Routes>
                {AppRoutes.map((route, index) => {
						const { element, ...rest } = route;
						return <Route key={index} {...rest} element={element} />;
					})}
				</Routes>
			</Layout>
		</LocalizationProvider>
	);
}

const isMobile = () => {
	return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(navigator.userAgent);
}

export default App;
//export const connection: string = "http://localhost:5254";
