import { Grid } from "@mui/material";
import { CheckLg, Clock, EnvelopeCheck, ExclamationOctagonFill } from "react-bootstrap-icons";
import { BudgetCategoryAmount, TransactionItem, USCurrency } from "../../types";

const CheckRequest = (props: any) => {
	let item: TransactionItem = props.Item;

	return (
		<Grid container className="btn-list text-app mt-3" onClick={props.OnClick}>
			<Grid item xs={8}>
				<h3>{item.merchant}</h3>
			</Grid>
			<Grid item xs={4} className="right">
				{item.checkRequest!.approvalState === -1 && (
					<div>Rejected</div>
				)}
				{item.checkRequest!.approvalState === 0 && (
					<div>Pending Approval</div>
				)}
				{item.checkRequest!.approvalState === 1 && (
					<div>Approved, Pending Payment</div>
				)}
				{item.checkRequest!.approvalState === 2 && (
					<div>Payed</div>
				)}
			</Grid>
			<Grid item xs={8} >
				{item.checkRequest!.approvalState < 2 ?
					<div>Needed by {item.checkRequest!.dateNeeded.toString()}</div> :
					<div>Payed on {item.transactionDate.toString()}</div>
				}
			</Grid>
			<Grid item xs={4} className="right">
				{item.checkRequest!.approvalState === -1 && (
					<ExclamationOctagonFill className="text-danger mx-2" />
				)}
				{item.checkRequest!.approvalState === 0 && (
					<Clock className="text-warning mx-2" />
				)}
				{item.checkRequest!.approvalState === 1 && (
					<CheckLg className="text-success mx-2" />
				)}
				{item.checkRequest!.approvalState === 2 && (
					<EnvelopeCheck className="text-success mx-2" />
				)}
				{USCurrency(item.budgetCategoryAmounts.reduce((total: number, a: BudgetCategoryAmount) => total + a.amount, 0))}
			</Grid>
		</Grid >
    );
}

export default CheckRequest;