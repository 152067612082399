import React from 'react';
import { Grid, TextField } from '@mui/material';

const Divider = (props: any) => {
	return (
		<Grid item xs={12}>
			<div className="section-divider text-app border-app">
				<span>{props.Text}</span>
			</div>
		</Grid>
	);
}

export default Divider;