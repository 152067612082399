import React from 'react';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { CurrencyDollar } from 'react-bootstrap-icons';

const Alert = (props: any) => {
	return (
		<Grid item xs={props.Size}>
			<TextField
				label={(props.NoLabel ? "" : props.Label)}
				variant="outlined"
				className={`form-control border-app ${props.Class} col-${props.Size}`}
				required={props.Required}
				value={props.Value}
				onKeyUp={props.OnKeyUp}
				onChange={props.OnChange} />
		</Grid>
	);
}

export default Alert;