import { useState } from "react";
import Description from "../Inputs/Description";
import Dropdown from "../Inputs/Dropdown";
import { AccountItem, DictToSelectable, SelectableItem, USCurrency, UserItem } from "../../types";
import { Grid } from "@mui/material";
import { AccountTypeDict } from "../../constants";
import ModalFooterSaveCancel from "./ModalFooterSaveCancel";
import ModalHeader from "./ModalHeader";

const EditAccount = (props: any) => {
	let [account, setAccount] = useState(props.Item as AccountItem);

	let users: UserItem[] = props.Users;
	let accountTypes = DictToSelectable(AccountTypeDict);

	return (<>
		<ModalHeader Title={`${account.accountId > 0 ? 'Edit' : 'Add'} Account`} CanDelete={props.OnDelete !== undefined} Close={props.Close} Delete={() => props.OnDelete(account)} />
		<div className="modal-body mt-5">
			<Grid container spacing="10">
				<Description Label="Description" Value={account.description} OnChange={(val: any) => UpdateDescription(val.target.value)} Size={12} />
				<Dropdown Size={6}
					Label="Account Type"
					Options={accountTypes}
					Index={0}
					Value={account.accountTypeId}
					OnChange={UpdateAccountType} />
				<Grid item xs={6} style={{ textAlign: 'right', alignSelf: 'center' }}>
					Balance: {USCurrency(account.amount)}
				</Grid>
				<Dropdown Size={12}
					Class="mb-0"
					Label="Primary User"
					Options={users
						.filter(u => u.isActive)
						?.map(u => {
							return {
								Id: u.userAccountId,
								Value: `${u.firstName} ${u.lastName}`
							} as SelectableItem;
						})}
					Value={account.userAccountId}
					OnChange={UpdateUserAccount} />
			{/*	<Toggle Size={12} OnChange={UpdateIsDefault} Checked={account.isDefault ?? false} Disabled={wasDefault} Label="Default Transaction Account" />*/}
			{/*	<Toggle Size={12} OnChange={UpdateAutoAdjust} Checked={account.autoAdjust ?? false} Label="Adjust account balance automatically" />*/}
			</Grid>
		</div>
		<ModalFooterSaveCancel ShowSave={ShouldShowSave()} Save={() => props.OnSave(account)} Close={props.Close} />
	</>);

	function ShouldShowSave() {
		return account.description !== null;
	}

	function UpdateDescription(newValue: string) {
		account = ({ ...account, description: newValue });
		setAccount(account);
	}
	function UpdateAccountType(newValue: number) {
		account = ({ ...account, accountTypeId: newValue });
		setAccount(account);
	}
	function UpdateUserAccount(newValue: number) {
		account = ({ ...account, userAccountId: newValue });
		setAccount(account);
	}
}

export default EditAccount