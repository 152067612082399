import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { ReceiptEmailItem, SelectableItem } from "../../types";
import Dropdown from "../Inputs/Dropdown";

const ImportQuickReceipt = (props: any) => {
	let items = props.Items as ReceiptEmailItem[];

	let [selectedReceipt, setSelectedReceipt] = useState((items && items.length > 0) ? items[0].receiptEmailId : 0);

	return (
		<Dialog open={props.Open} onClose={props.Close}>
			<DialogContent className="px-5 mt-3">
				<Dropdown Size={12}
					Class="mb-0 mx-3"
					Label="Select a quick receipt"
					Options={items.map((r: ReceiptEmailItem) => {
						return {
							Id: r.receiptEmailId,
							Value: r.emailSubject
						} as SelectableItem;
					})}
					Value={selectedReceipt}
					OnChange={(value: number, index: any) => setSelectedReceipt(value)} />
			</DialogContent>
			<DialogActions className="px-3">
				<Button className="mx-3 my-4" onClick={props.Close} variant="outlined">Cancel</Button>
				<Button onClick={() => props.Confirm(selectedReceipt)} variant="contained" className="bg-success mx-3">Confirm</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ImportQuickReceipt;