import { Grid } from "@mui/material";
import { ReceiptEmailItem } from "../../types";

const ReceiptEmail = (props: any) => {
    let item: ReceiptEmailItem = props.Item;

	return (
		<Grid container className="btn-list text-app mx-5" onClick={props.OnClick}>
			<Grid item xs={12} >
				<h3>{item.emailSubject}</h3>
			</Grid>
		</Grid >
    );
}

export default ReceiptEmail;