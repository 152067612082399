/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import User from "../components/ListItems/User";
import EditUser from "../components/Modals/EditUser";
import { BudgetItem, CurrentUser, FamilyCode, FamilyHasAccess, UserItem } from "../types";
import Divider from "../components/Inputs/Divider";
import { Button, Grid } from "@mui/material";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useItems } from "../features/Fetch/Items";
import Code from "../components/ListItems/Code";
import EditCode from "../components/Modals/EditCode";
import { useNavigate } from "react-router-dom";
import { pageLoad } from "../App";
import { useAuth } from "../features/Fetch/Auth";
import { Features } from "../constants";

const Users = (props: any) => {
	useEffect(() => {
		HideModal();
		if (user.isHoH) {
			LoadData();
		} else {
			navigate('/');
		}
	}, []);

	let dispatch = useDispatch();
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let [codes, setCodes] = useState(undefined as FamilyCode[] | undefined);

	return (<>
		{user.isHoH && users && codes && FamilyHasAccess(user, Features.Users) && <>
			{FamilyHasAccess(user, Features.FamilyCodes) &&
				<Grid container>
					<Grid item xs={8}>
						<h2 className="mt-2 text-app">Codes</h2>
					</Grid>

					{codes === null ?
						<p><em>Loading...</em></p>
						:
						<Grid item xs={4}>
							<Button className="mt-2 text-white bg-success" variant="contained" onClick={OpenNewModal}>New Code</Button>
						</Grid>
					}
				</Grid>
			}
			<Divider />
			{codes !== null && <>
				{codes.slice().sort((a, b) => a.familyCodeId - b.familyCodeId).map(code => {
					return <Code Item={code} OnClick={() => OpenCodeModal(code.familyCodeId)} />
				})}
				{codes.length === 0 &&
					<h5>You currently have no family codes. To generate a code, select New Code at the top of the page.</h5>
				}
			</>}

			<Divider />

			<Grid container>
					<Grid item xs={8}>
					<h2 className="mt-2 text-app">Users</h2>
				</Grid>
			</Grid>

			<Divider />
			{users != null && <>
				{users.slice().sort((a, b) => a.userAccountId - b.userAccountId).map((user: UserItem) => {
					return <User Item={user} Class="text-app mt-1" OnClick={() => OpenUserModal(user.userAccountId)} />
				})}
			</>}
			{users.length === 0 && <>
				<h5>You currently have no users in your family except yourself. To invite a new user to your family, select New Code at the top of the page and give that code to whomever you wish to invite.</h5>
			</>}
			{users.filter(u => u.isAdmin).length === 0 && <>
				<h5>Your subsciption currently includes 2 admin accounts. To add an additional admin account, select an active user and mark them as admin or select New Code to add a new user.</h5>
			</>}

			<Divider />
			</>}
		</>);

	async function LoadData() {
		if (codes && codes.length > 0) return;
		let c = await repo.GetFamilyCodesAsync();
		setCodes(c);
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}
	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function SaveUser(item: UserItem) {
		repo.UpdateUserAsync(item, item.userAccountId === 0, false);
		budgets.forEach(budget => {
			if (item.budgetIds?.includes(budget.budgetId) && !budget.userIds.includes(item.userAccountId)) {
				budget = {
					...budget,
					userIds: [...budget.userIds, item.userAccountId]
				};
				repo.UpdateBudgetAsync(budget, budget.variant);
			} else if (!item.budgetIds?.includes(budget.budgetId) && budget.userIds.includes(item.userAccountId)) {
				budget = {
					...budget,
					userIds: [...budget.userIds].filter(u => u !== item.userAccountId)
				};
				repo.UpdateBudgetAsync(budget, budget.variant);
			}
		});
		HideModal();
	}

	function DeleteUser(item: UserItem) {
		item.familyId = -2;
		SaveUser(item);
	}

	async function CreateCode(item: FamilyCode, email: string) {
		setCodes(await repo.GenerateFamilyCodesAsync(codes!, false, email));
		HideModal();
	}

	function SendCode(item: FamilyCode, email: string) {
		repo.SendFamilyCodesAsync(item.code, email);
		HideModal();
	}

	function DeleteCode(item: FamilyCode) {
		repo.UpdateFamilyCodesAsync(item, codes!, false, true);
		HideModal();
	}

	function OpenNewModal() {
		let code = {
			familyCodeId: 0,
			familyId: 0,
			code: '',
			isAdmin: false,
			canApproveChecks: false
		} as FamilyCode;
		modal = {
			...modal,
			Body: <EditCode Item={code} OnSave={CreateCode} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenCodeModal(idx: number) {
		let code = codes!.find(t => t.familyCodeId === idx);
		if (code) {
			modal = {
				...modal,
				Body: <EditCode Item={code} OnSave={SendCode} OnDelete={DeleteCode} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenUserModal(idx: number) {
		let user = users.find(t => t.userAccountId === idx);
		if (user) {
			user = {
				...user,
				budgetIds: budgets.filter(b => b.userIds.includes(user?.userAccountId ?? 0)).map(b => b.budgetId)
			}
			modal = {
				...modal,
				Body: <EditUser Item={user} Budgets={budgets} OnSave={SaveUser} OnDelete={DeleteUser} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default Users;