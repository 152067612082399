import { Grid } from "@mui/material";
import { ArrowRepeat, Cash, ExclamationOctagonFill, Image, Pencil, PencilSquare, QuestionDiamond, Tag, XSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { useItems } from "../../features/Fetch/Items";
import { CurrentUser, FamilyHasAccess, TransactionItem, USCurrency } from "../../types";

const Transaction = (props: any) => {
    let item: TransactionItem = props.Item;
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let repo = useItems();
	let localData = repo.GetLocalData();

	return (
		<Grid container className="btn-list" style={{ color: props.Color }} onClick={props.OnClick}>
			<Grid item xs={1} className="centered" >
				{item.hasReceipt && (
					<Image />
				)}
				{!item.hasReceipt && !FamilyHasAccess(user, Features.Income) && (
					<XSquare className="text-warning" />
				)}
				{!item.hasReceipt && FamilyHasAccess(user, Features.Income) && item.budgetCategoryAmounts[0].categoryId <= 0 && localData.ShowNeedsEdit &&
					<PencilSquare className="text-warning" />
				}
			</Grid>
			<Grid item xs={7} >
				<h3>{item.merchant}</h3>
			</Grid>
			<Grid item xs={4} className="right">
				{FamilyHasAccess(user, Features.Taxes) && !item.miles && item.rate != null && localData.ShowHasTaxes && (
					<Tag className="mx-2" />
				)}
				{item.recurringTransactionId && item.recurringTransactionId > 0 && localData.ShowRepeating && (
					<ArrowRepeat className="mx-2" />
				)}
				{item.needsReimbursement && !item.completedReimbursement && localData.ShowNeedsReimbursement && (
					<Cash className="mx-2" />
				)}
				{item.budgetCategoryAmounts.filter(c => c.isRejected).length > 0 && (
                    <ExclamationOctagonFill className="text-danger mx-2" />
                )}
                {item.isAdminReview && (
                    <QuestionDiamond className="text-app mx-2" />
				)}
				{item.isIncome && (
					<b>+</b>
				)}
				{USCurrency(item.budgetCategoryAmounts.reduce((total, b) => total + b.amount, 0))}
				{props.HasExternal && !item.isExternal && (
					<Pencil className="text-app mx-2" />
				)}
			</Grid>
		</Grid >
    );
}

export default Transaction;