import { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, IconButton, Button, Backdrop } from "@mui/material";
import Map, { Layer, MapRef, Marker, Source } from 'react-map-gl';
import GeocoderControl from "../Inputs/GeocoderControl";
import * as turf from '@turf/turf';
import "../../styles/Mapbox.css";
import { Geometry, GeoJsonProperties } from "geojson";
import WebMercatorViewport from 'viewport-mercator-project'
import { X } from "react-bootstrap-icons";
import { FeatureCollection } from "@turf/turf";
import html2canvas from "html2canvas";
import FileResizer from "react-image-file-resizer";

const token = 'pk.eyJ1IjoiZnJlZWxpZmVhcHBzIiwiYSI6ImNsb3B5a2h6dTBjMXcyaXFxNnBubnVqNnUifQ.Ai5E9ZbUBm9o2JvQyVEjzg';

const MapView = (props: any) => {
	let [viewport, setViewport] = useState({
		latitude: 0,
		longitude: 0,
		zoom: 1,
	} as any);
	let [map, setMap] = useState({} as MapRef);
	let mapRef = useCallback((node: MapRef) => {
		setMap(node);
	}, []);
	let [overlay, setOverlay] = useState(false);
	let [distance, setDistance] = useState(0);
	let [locations, setLocations] = useState(turf.featureCollection<any, GeoJsonProperties>([]));
	let [nothing, setNothing] = useState(turf.featureCollection<Geometry, GeoJsonProperties>([]));

	let geocoder = useRef({} as GeocoderControl);
	let imgContainer = useRef(null as any);
	let finalRef = useRef(null as any);

	useEffect(() => {
		console.log({ viewport });
	}, [viewport]);

	let stopCounter = -1;
	return (
		<Grid container>
			<div ref={imgContainer} style={{ width: '100%' }}>
				<div className="map-container">
					<Map
						ref={mapRef}
						initialViewState={viewport}
						mapboxAccessToken={token}
						mapStyle="mapbox://styles/mapbox/streets-v9"
						onDragEnd={UpdateViewport}
						onZoom={UpdateViewport}
						preserveDrawingBuffer={true}
					>
						<GeocoderControl
							ref={geocoder}
							mapRef={map}
							onResult={HandleOnResult}
							mapboxApiAccessToken={token}
							position='bottom-left'
							containerNode={finalRef.current}
							placeholder='Add Stop'
						/>
						<Source id="lines" type='geojson' data={nothing}>
							<Layer
								id='line'
								type='line'
								paint={{
									"line-color": '#007cbf',
									'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12]
								}}
							/>
							<Layer
								id='arrows'
								type='symbol'
								layout={{
									"symbol-placement": 'line',
									"text-field": '▶',
									"text-size": ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
									"symbol-spacing": ['interpolate', ['linear'], ['zoom'], 12, 100, 22, 160],
									"text-keep-upright": false
								}}
								paint={{
									"text-color": '#3887be',
									"text-halo-color": 'hsl(55, 11%, 96%)',
									"text-halo-width": 3
								}}
							/>
						</Source>
						{locations.features.map(result => {
							return <Marker longitude={result.geometry.coordinates[0]} latitude={result.geometry.coordinates[1]} />;
						})}
					</Map>
				</div>
				<div style={{ marginTop: '30px', marginBottom: '30px' }}>
					Route:
					<table>
						{locations.features.map(location => {
							let idx = stopCounter + 1;
							return <tr>
								<td style={{ width: '10px', textAlign: 'right' }}>
									<IconButton
										className="cursor-pointer"
										aria-label='close'
										onClick={() => RemoveResult(idx)}>
										<X />
									</IconButton>
								</td>
								<td style={{ textAlign: 'right' }}>
									{++stopCounter === 0 ?
										"Start: " :
										stopCounter === locations.features.length - 1 ?
											"End:" :
											`Stop${stopCounter}:`}
								</td>
								<td style={{ width: '10px', textAlign: 'right' }}></td>
								<td style={{ textAlign: 'left' }}>
									{location.properties?.placeName.replace(', United States', '')}
								</td>
								<td style={{ width: '10px', textAlign: 'right' }}></td>
								<td style={{ textAlign: 'left' }}>
									{(location.properties?.distance ?? 0) > 0 &&
										`${location.properties!.distance} mi`}
								</td>
							</tr>
						})}
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td style={{ textAlign: 'right' }}><b>Total</b></td>
							<td></td>
							<td>{distance} miles</td>
						</tr>
					</table>
				</div>
			</div>
			<div className="row" style={{ width: '100%' }}>
				<Grid item xs={12} ref={finalRef} className="mb-3 bg-app py-2"></Grid>
				<Grid item xs={6}>
					{distance > 0 && <Button variant="contained" className="bg-success text-white mb-3" onClick={SaveMap}>Save Map Image</Button>}
				</Grid >
				<Grid item xs={6} style={{ textAlign: 'end' }}>
					<Button className="border-success text-success mb-3" onClick={props.OnCancel}>Enter Manually</Button>
				</Grid>
			</div>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
				<CircularProgress />
			</Backdrop>
		</Grid>
	);

	async function HandleOnResult(event: any, vp: any) {

		console.log('Updating');

		SetViewport({ ...vp });

		let points = SetNavPoints(event, { ...vp });
		let dist = await SetNav(points);

		geocoder.current.handleClear.call(geocoder);

		props.OnCalc(dist);
	}

	async function RemoveResult(idx: number) {
		locations.features.splice(idx, 1);
		let points = { ...locations }
		if (points.features.length > 1) {
			SetViewport({
				longitude: points.features[0].geometry[0],
				latitude: points.features[0].geometry[1],
				zoom: viewport.zoom
			})
		}
		let dist = await SetNav(points);

		props.OnCalc(dist);
	}

	function UpdateViewport(value: any) {
		setViewport({
			latitude: value.viewState.latitude,
			longitude: value.viewState.longitude,
			zoom: value.viewState.zoom
		});
	}

	function AssembleUrl(points: any) {
		let coordinates: any[] = [];
		let stops = [];

		//let restJobs = Object.keys(pointHopper).map((key) => pointHopper[key]);

		if (points.features.length > 0) {
			let stopIndex = coordinates.length;

			for (let job of points.features) {
				coordinates.push(job.geometry.coordinates);
				if (coordinates.length > 1) {
					stops.push(`${stopIndex},${coordinates.length - 1}`);
				}
			}
		}

		return `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates.join(
			';'
		)}?geometries=geojson&access_token=${token}`;
	}

	function SetViewport(vp: any) {
		const fitBounds = (bounds: any, viewport: any) => {
			return new WebMercatorViewport(viewport).fitBounds(bounds)
		}

		try {
			let longlats = { ...locations }.features.map(f => {
				return f.geometry.coordinates! as number[];
			})
			longlats.push([vp.longitude, vp.latitude]);
			let { width, height } = map.getMap()
				.getContainer()
				.getBoundingClientRect();
			let bound = longlats.reduce((bounds: number[][], coord: number[]) => {
				if (coord[0] > bounds[0][0]) {
					bounds[0][0] = coord[0];
				}
				if (coord[1] > bounds[0][1]) {
					bounds[0][1] = coord[1];
				}
				if (coord[0] < bounds[1][0]) {
					bounds[1][0] = coord[0];
				}
				if (coord[1] < bounds[1][1]) {
					bounds[1][1] = coord[1];
				}
				return bounds;
			}, [[...longlats[0]], [...longlats[0]]]);
			let bounds = fitBounds(bound, { width, height });
			vp = {
				...vp,
				longitude: bounds.longitude,
				latitude: bounds.latitude,
				zoom: bounds.zoom - 0.5
			};
		} catch (e) {
			console.log(e);
		}

		setViewport({
			...vp,
		});
		map.easeTo({ center: [vp.longitude, vp.latitude], zoom: vp.zoom });
	}

	function SetNavPoints(event: any, vp: any) {
		let pt = turf.point([vp.longitude, vp.latitude], { placeName: event.result.place_name, key: Math.random() });

		return { ...locations, features: [...locations.features, pt] };
	}

	async function SetNav(points: FeatureCollection<any, GeoJsonProperties>) {

		if (points.features.length > 1) {
			let query = await fetch(AssembleUrl(points), { method: 'GET' });
			let response = await query.json();

			if (response.code !== 'Ok') {
				let handleMessage = response.code === 'InvalidInput' ?
					'Refresh to start a new route.' :
					'Try a different point.';
				alert(`${response.code} - ${response.message}\n\n${handleMessage}`);
				points.features.pop();
				return;
			}

			let legs = response.routes[0].legs;
			let distancePoints = [{ ...points.features[0] }];
			for (let i = 0; i < legs.length; i++) {
				distancePoints.push({ ...points.features[i + 1], properties: { ...points.features[i + 1].properties, distance: parseFloat((legs[i].distance / 1609.344).toFixed(2)) } });
			}
			points = { ...locations, features: distancePoints };
			setLocations({ ...points });

			let routeGeoJSON = turf.featureCollection([turf.feature(response.routes[0].geometry)]);
			setNothing(routeGeoJSON);

			let dist = parseFloat((response.routes[0].distance / 1609.344).toFixed(2))
			setDistance(dist);
			return dist;
		}
		else {
			setLocations({ ...points });
			setNothing(turf.featureCollection<Geometry, GeoJsonProperties>([]));
			setDistance(0);
		}
	}

	function SaveMap() {
		setOverlay(true);
		setTimeout(RenderImage, 50);
	}

	function RenderImage() {
		html2canvas(imgContainer.current, { allowTaint: true, useCORS: true, scale: 1 }).then((dataUrl) => {
			dataUrl.toBlob((result) => {
				new Promise(resolve =>
					FileResizer.imageFileResizer(result!, 1280, 6400, 'jpg', 100, 0, uri => resolve(uri))
				).then((resizedFile: any) => {
					props.OnSave(resizedFile?.toString()?.substring(resizedFile.toString().indexOf(',') + 1));
				});
			});
		});
	}
}
export default MapView;