import { useCallback, useEffect, useState } from "react";
import { PlusLg } from "react-bootstrap-icons";
import { usePlaidLink } from "react-plaid-link";
import { useFetch } from "../../features/Fetch/Fetch";
import { useItems } from "../../features/Fetch/Items";

const Link = (props: any) => {
	let [token, setToken] = useState(null);

	let request = useFetch();
	let items = useItems();

	useEffect(() => {
		request.getRaw("Settings/CreateLinkToken/false/0")
			.then(response => {
				response.text().then((data: any) => {
					setToken(data);
				});
			});
	}, []);

	const onSuccess = useCallback(async (publicToken: any, metadata: any) => {
		request.postRaw("Settings/ExchangePublicToken", { public_token: publicToken, metadata })
			.then(response => {
				response.text().then((data: any) => {
					items.GetAccountsAsync();
				});
			});
	}, []);

	let isOauth = false;

	const config: any = {
		token,
		onSuccess,
	};

	const { open, ready } = usePlaidLink(config);

	useEffect(() => {
		if (isOauth && ready) {
			open();
		}
	}, [token, isOauth, ready, open]);

	return (
		<div className="col-12">
			<div className="btn btn-account btn-list text-app" onClick={() => open()}>
				<h3>
					<PlusLg /> Link New Financial Institution
				</h3>
			</div>
		</div>
	);
}

export default Link;