import { useState } from "react";

import { Button, Grid } from "@mui/material";
import Error from "../components/Error";
import logo from '../images/logo2.png'
import TextInput from "../components/Inputs/TextInput";
import { FamilyCode } from "../types";
import { useFetch } from "../features/Fetch/Fetch";

let initialStateVars: FamilyCode = {
	familyCodeId: 0,
	code: '',
	isAdmin: false
};

const Inactive = (props: any) => {
	let fetch = useFetch();

	let [stateVars, setStateVars] = useState(initialStateVars);
	let [error, setError] = useState('');

	return (
		<div className="login page">
			<div className="form-edit centered">
				<div className="centered mt-20 text-success">
					<h5>Welcome to</h5>
				</div>
				<img className="img-title" src={logo} alt="Shekl" />
				<Grid container rowSpacing={3} justifyContent="center">
					<Grid item xs={8}>
						<h4>Thank you for choosing Shekl!</h4>
						You should have access to the site as soon as your family manager marks you as active. If you believe you should already be active, please contact your family manager to verify your status.
					</Grid>
					<Grid item xs={8}>
						If you have a new family code, you may enter it below to initiate connection to a new family. This will remove you from any family you are currently connected to.
					</Grid>
					<Grid item xs={8}>
						Note: Entering a family code does not activate your account automatically. After you've entered a family code, you must wait for the family manager to activate your account.
					</Grid>
					<TextInput Required Id="inputCode" Label="Enter a new Family Code" Value={stateVars.code} Size={8} OnChange={(e: string) => CodeChange(e)} />
					<Grid item xs={8}>
						<Button variant="contained" className="centered col-12 bg-success btn-large" onClick={OnSubmit}>Submit</Button>
					</Grid>
					<Grid item xs={8}>
						<Error Text={error} />
					</Grid>
				</Grid>
			</div>
		</div>
	);

	function CodeChange(input: any) {
		setStateVars({
			...stateVars, code: input.target.value
		});
	}

	async function OnSubmit() {
		try {
			await fetch.get(`User/Codes/Link/${stateVars.code}`);
			CodeChange('');
		} catch { }
		setError("If your code was valid, the family manager should be able to activate your account.");
	}
}

export default Inactive;