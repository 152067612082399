import { Grid } from "@mui/material";
import { BudgetItem, CategoryItem, DateTime, USCurrency } from "../../types";

const Budget = (props: any) => {
	let item: BudgetItem = props.Item;

	let percUsed = `${Math.max(0, 100 - getPerc((new DateTime()).dayDiff(item.variant.startDate), item.endDate!.dayDiff(item.variant.startDate), 100) - getPerc(item.amountRemaining, item.variant.amount, 0))}%`;
	let percUnused = `${getPerc(item.amountRemaining, item.variant.amount, 0)}%`;
	let percUsedErr = '0%';

	if (item.amountRemaining < 0) {
		percUsedErr = '100%';
		percUsed = '0%';
		percUnused = '0%'
	}

	if (item.variant.amount === 0) {
		percUnused = '100%';
		percUsed = '0%';
		percUsedErr = '0%';
	}

	let catString = props.Categories.filter((c: CategoryItem) => item.categoryIds.includes(c.categoryId))!.map((c: CategoryItem) => c.description).join(", ")
	let categories = catString.length < 18 ?
		catString :
		`${catString.substring(0, 15)}...`;

	let color = item.color;
	let bg = 'white';
	if (item.variant.startDate.dayDiff(new DateTime()) > 0) {
		color += '88';
		bg = 'light'
	}

	return (
		<Grid container className={`btn-list border-app btn-budget bg-${bg}`} style={{ color: color }} onClick={props.OnClick}>
			<Grid item xs={5}>
				<h3>{item.description}</h3>
			</Grid>
			<Grid item xs={7} className="right">
				{categories}
			</Grid>
            <Grid item xs={12}>
                <>{item.variant.startDate.toString()} to {item.endDate!.addDays(-1).toString()}</>
            </Grid>
			<Grid item xs={12} className="right">
				<div className="back-bar">
					<div className="white-bar" style={{ width: percUnused }}></div>
					<div className="yellow-bar" style={{ width: percUsed }}></div>
					<div className="red-bar" style={{ width: percUsedErr }}></div>
				</div>
			</Grid>
			<Grid item xs={4} className="text-success">
				{USCurrency(item.amountSpent)}
				{(item.variant.amount > 0) && <> of {USCurrency(item.variant.amount).substring(1)}</>}
			</Grid>
			<Grid item xs={8} className={`right ${item.amountRemaining > 0 ? 'text-app' : 'text-danger'}`}>
				{(item.variant.amount > 0) && <>{USCurrency(Math.abs(item.amountRemaining))} {item.amountRemaining >= 0 ? "left" : "over"}</>}
            </Grid>
		</Grid >
    );

	function getPerc(value: number, total: number, def: number) {
		var result =
			total > 0 ?
				100 * value / total :
				value !== 0 ?
					def :
					100 - def;
		if (result > 100) {
			result = 100;
		}
		if (result < 0) {
			result = 0;
		}
		return result;
	}
}

export default Budget;