import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../features/Fetch/Auth";

interface VerifyProps {
	Logo: string,
	Email: string,
	LoginClicked: () => void
}
const Verify = (props: VerifyProps) => {
	let auth = useAuth();

	let [error, setError] = useState('');

	return (
		<>
			<div className="centered mt-20 text-success">
				<h5>Thank you for Registering for</h5>
			</div>
			<img className="img-title" src={props.Logo} alt="Shekl" />
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<h5>{error}</h5>
				</Grid>
				<Grid item xs={8}>
					An email was sent to {props.Email} with a verification link. You will need to click the link in that email to verify your email address before you can log in.
				</Grid>
				<Grid item xs={8}>
					If you do not see the email, you may need to wait a little longer to receive it or check your Spam/Junk folder. If you have lost or deleted the email or feel it was never delivered, you may click the link below to send a new email.
				</Grid>
				<Grid item xs={12}>
					<Button variant="text" className="centered" onClick={resendEmail}>Send Email Again</Button>
				</Grid>
				<Grid item xs={12}>
					<Button variant="text" className="centered" onClick={props.LoginClicked}>Back to Login</Button>
				</Grid>
			</Grid>
		</>
    );

	async function resendEmail() {
		var email = props.Email.replace('@', '%40');
		auth.resendEmailVerification(email);
		setError(`A new verification email has been sent to ${props.Email}.`);
	}
};

export default Verify;