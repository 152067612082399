/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Dropdown from "../Inputs/Dropdown";
import { AccountItem, DateTime, RecurringItem, SelectableItem, USCurrency } from "../../types";
import { Grid } from "@mui/material";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import ModalHeader from "./ModalHeader";
import ModalFooterCustom from "./ModalFooterCustom";
import Recurring from "../ListItems/Recurruing";

interface DateRange {
	Id: number,
	Start: DateTime,
	End: DateTime
}

interface DailyBalance {
	Date: DateTime,
	Start: number,
	End: number,
	Expenses: RecurringItem[],
	Paydays: RecurringItem[]
}

const UpcomingReview = (props: any) => {
	let [startDate, setStartDate] = useState((new DateTime()).startOfDay());
	let [endDate, setEndDate] = useState((new DateTime()).startOfDay());
	let [account, setAccount] = useState(0);
	let [range, setRange] = useState({ Id: 0, Start: new DateTime(), End: new DateTime().addDays(7) } as DateRange);
	let [rangesWeek, setRangesWeek] = useState([] as DateRange[]);
	let [rangesBiWeek, setRangesBiWeek] = useState([] as DateRange[]);
	let [rangesMonth, setRangesMonth] = useState([] as DateRange[]);
	let [ranges3Month, setRanges3Month] = useState([] as DateRange[]);
	let [ranges6Month, setRanges6Month] = useState([] as DateRange[]);
	let [rangesYear, setRangesYear] = useState([] as DateRange[]);
	let [allRanges, setAllRanges] = useState([] as DateRange[]);
	let [overdue, setOverdue] = useState(0);
	let [overdueExpenses, setOverdueExpenses] = useState([] as RecurringItem[]);
	let [overduePaydays, setOverduePaydays] = useState([] as RecurringItem[]);
	let [dailies, setDailies] = useState([] as DailyBalance[]);
	let accounts: AccountItem[] = props.Accounts;
	let transactions: RecurringItem[] = props.Transactions;

	let selectedAccount = accounts.find(a => a.accountId === account);
	if (startDate >= new DateTime()) {
		for (let i = 0; i < transactions.length; i++) {
			transactions[i] = { ...transactions[i] };
			while (transactions[i].nextDate > endDate) {
				transactions[i].nextDate = transactions[i].nextDate.addDays(-1 * transactions[i].days).addMonths(-1 * transactions[i].months);
			}
			while (transactions[i].nextDate < startDate) {
				transactions[i].nextDate = transactions[i].nextDate.addDays(transactions[i].days).addMonths(transactions[i].months);
			}
		}
	}
	let selectedTransactions = transactions.filter(t => t.accountId === account && ((t.nextDate >= startDate && t.nextDate <= endDate) || (t.nextDate >= startDate.addDays(t.days).addMonths(t.months) && t.nextDate <= endDate.addDays(t.days).addMonths(t.months))));
	let selectedExpenses = selectedTransactions.filter(t => !t.isIncome);
	let oldExpenses = selectedExpenses.filter(t => t.nextDate > endDate);
	let upcoming = selectedExpenses.filter(t => t.nextDate <= endDate);
	let selectedIncome = selectedTransactions.filter(t => t.isIncome);
	let balance = selectedAccount?.amount ?? 0;
	let remainingIncome = GetTotal(selectedIncome.filter(t => t.nextDate <= endDate));

	return (<>
		<ModalHeader Title={`Upcoming`} Close={props.Close} />
		<div className="modal-body mt-5">
			<Grid container columnSpacing={3}>
				<Dropdown Size={12}
					Class="mb-0"
					Label="Account"
					Options={GetAccountDict()}
					Value={account}
					OnChange={UpdateAccount} />
				<Dropdown Size={12}
					Class="mb-0"
					Label="Pay Period"
					Options={GetPayPeriods()}
					Value={range.Id}
					OnChange={UpdatePayPeriod} />
				<Grid item xs={6}>Current Balance</Grid>
				<Grid item xs={6}>{USCurrency(balance)}</Grid>
				<Grid item xs={6}>Total Expense</Grid>
				<Grid item xs={6}>{USCurrency(GetTotal(selectedExpenses))}</Grid>
				<Grid item xs={6}>Total Income</Grid>
				<Grid item xs={6}>{USCurrency(GetTotal(selectedIncome))}</Grid>
				<Grid item xs={6}>Remaining Expense</Grid>
				<Grid item xs={6}>{USCurrency(GetTotal(upcoming))}</Grid>
				<Grid item xs={6}>Remaining Income</Grid>
				<Grid item xs={6}>{USCurrency(remainingIncome)}</Grid>
				<Grid item xs={6}>Expected End Balance</Grid>
				<Grid item xs={6}>{USCurrency(balance - GetTotal(upcoming) + remainingIncome)}</Grid>
				<Grid item xs={12} className="centered text-app mt-5"><h2>Already Paid</h2></Grid>
				<Grid item xs={12}>{oldExpenses.map(transaction => <>
					<Recurring
						Item={transaction}
						key={`transaction${transaction.recurringTransactionId}`} />
				</>)}</Grid>
				<Grid item xs={12} className="centered text-app mt-5"><h2>Still To Be Paid</h2></Grid>
				<Grid item xs={12}>{upcoming.map(transaction => <>
					<Recurring
						Item={transaction}
						key={`transaction${transaction.recurringTransactionId}`} />
				</>)}</Grid>
			</Grid>
		</div>
		<ModalFooterCustom Close={props.Close} />
	</>);

	function GetTotal(transacts: RecurringItem[]) {
		return transacts.reduce((total: number, a: RecurringItem) => total + a.nextAmount, 0);
	}
	function GetAccountDict() {
		let result: SelectableItem[] = [];
		accounts.forEach(b => result.push({
			Id: b.accountId,
			Value: b.description
		}));
		if (account === 0) setAccount(40);
		return result;
	}
	function GetPayPeriods() {
		let result: SelectableItem[] = [];
		let incomes = transactions.filter(t => t.accountId === account && t.isIncome);
		result = result.concat(GetWeeklyPayPeriods(incomes));
		result = result.concat(GetBiWeeklyPayPeriods(incomes));
		result = result.concat(GetMonthlyPayPeriods(incomes));
		result = result.concat(Get3MonthlyPayPeriods(incomes));
		result = result.concat(Get6MonthlyPayPeriods(incomes));
		result = result.concat(GetYearlyPayPeriods(incomes));
		let ar = rangesWeek.concat(rangesBiWeek).concat(rangesMonth).concat(ranges3Month).concat(ranges6Month).concat(rangesYear);
		if (allRanges.length !== ar.length) {
			setAllRanges(ar);
		}
		UpdatePayPeriod(range.Id);
		return result;
	}
	function GetWeeklyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.days === 7);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "Weekly"
			});
			if (rangesWeek.length === 0) {
				let startDates = GetStartDates(items, -7, 0);
				let count = 0;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						rangesWeek.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addDays(6)
						});
						s.Value = s.addDays(7).Value;
					});
				}
				setRangesWeek(rangesWeek);
			}
			rangesWeek.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function GetBiWeeklyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.days === 14);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "Bi-Weekly"
			});
			if (rangesBiWeek.length === 0) {
				let startDates = GetStartDates(items, -14, 0);
				let count = 10;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						rangesBiWeek.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addDays(13)
						});
						s.Value = s.addDays(14).Value;
					});
				}
				setRangesBiWeek(rangesBiWeek);
			}
			rangesBiWeek.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function GetMonthlyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.months === 1);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "Monthly"
			});
			if (rangesMonth.length === 0) {
				let startDates = GetStartDates(items, 0, -1);
				let count = 20;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						rangesMonth.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addMonths(1).addDays(-1)
						});
						s.Value = s.addMonths(1).Value;
					});
				}
				setRangesMonth(rangesMonth);
			}
			rangesMonth.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function Get3MonthlyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.months === 3);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "3 Months"
			});
			if (ranges3Month.length === 0) {
				let startDates = GetStartDates(items, 0, -3);
				let count = 30;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						ranges3Month.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addMonths(3).addDays(-1)
						});
						s.Value = s.addMonths(3).Value;
					});
				}
				setRanges3Month(ranges3Month);
			}
			ranges3Month.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function Get6MonthlyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.months === 6);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "6 Months"
			});
			if (ranges6Month.length === 0) {
				let startDates = GetStartDates(items, 0, -6);
				let count = 40;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						ranges6Month.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addMonths(6).addDays(-1)
						});
						s.Value = s.addMonths(6).Value;
					});
				}
				setRanges6Month(ranges6Month);
			}
			ranges6Month.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function GetYearlyPayPeriods(incomes: RecurringItem[]): SelectableItem[] {
		let result: SelectableItem[] = [];
		let items = incomes.filter(i => i.months === 12);
		if (items.length > 0) {
			result.push({
				Id: -2,
				Value: "Yearly"
			});
			if (rangesYear.length === 0) {
				let startDates = GetStartDates(items, 0, -12);
				let count = 50;
				for (let i = 0; i < 8; i++) {
					// eslint-disable-next-line no-loop-func
					startDates.forEach(s => {
						rangesYear.push({
							Id: count++,
							Start: new DateTime(s.Value),
							End: s.addMonths(12).addDays(-1)
						});
						s.Value = s.addMonths(12).Value;
					});
				}
				setRangesYear(rangesYear);
			}
			rangesYear.forEach(r => result.push({
				Id: r.Id,
				Value: `${r.Start} - ${r.End}`
			}));
		}
		return result;
	}
	function GetStartDates(items: RecurringItem[], days: number, months: number): DateTime[] {
		let startDates = [] as DateTime[];
		items.forEach(b => {
			if (startDates.filter(s => s.Value === b.nextDate.Value).length === 0) {
				let s = new DateTime(b.nextDate.startOfDay().Value);
				while (s > new DateTime()) {
					s = new DateTime(s.addDays(days).addMonths(months).Value);
				}
				startDates.push(s);
			}
		});
		return startDates.sort((a, b) => {
			return a.diff(b);
		});
	}
	function UpdateAccount(newValue: any) {
		setAccount(newValue);
		setAllRanges([]);
		setRangesWeek([]);
		setRangesBiWeek([]);
		setRangesMonth([]);
		setRanges3Month([]);
		setRanges6Month([]);
		setRangesYear([]);
	}
	function UpdatePayPeriod(newValue: any) {
		if (allRanges.length > 0) {
			let selected = allRanges.find(r => r.Id === newValue);
			if (selected && selected !== range) {
				setRange(selected);
				UpdateStartDate(selected.Start);
				UpdateEndDate(selected.End);
			}
		}
	}
	function UpdateStartDate(newValue: any) {
		setStartDate(new DateTime(newValue));
	}
	function UpdateEndDate(newValue: any) {
		setEndDate(new DateTime(newValue));
	}
}


export default UpcomingReview;