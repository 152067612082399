/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AccountItem, CurrentUser, DateTime, FamilyFeatureValue, FamilyHasAccess, RecurringItem, SelectableItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Button, Grid, IconButton } from "@mui/material";
import { PlusLg } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { pageLoad } from "../App";
import { useAuth } from "../features/Fetch/Auth";
import { Features } from "../constants";
import Recurring from "../components/ListItems/Recurruing";
import EditRecurring from "../components/Modals/EditRecurring";
import Dropdown from "../components/Inputs/Dropdown";
import { useNavigate } from "react-router-dom";
import UpcomingReview from "../components/Modals/UpcomingReview";

const Recurrings = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (!user.isHoH) navigate('/');
		if (pageLoad.instance === 2 && user.token && user.isActive && user.isHoH) {
			auth.setUserContext().then(v => {
				repo.GetAccountsAsync();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
				repo.GetRecurringTransactionsAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let accounts: AccountItem[] = useSelector((state: any) => state.accounts.value);

	let [selected, setSelected] = React.useState(0);

	let transactions = repo.SortedRecurringTransactions();
	if (transactions && selected === 1) {
		transactions = transactions.filter(t => !t.isIncome);
	} else if (transactions && selected === 2) {
		transactions = transactions.filter(t => t.isIncome);
	}

	let budgetTree = repo.GetBudgetTree();

	let options: SelectableItem[] = [
		{ Id: 0, Value: 'All' },
		{ Id: 1, Value: 'Expenses' },
		{ Id: 2, Value: 'Income' },
	];

	if (!FamilyHasAccess(user, Features.Recurring)) {
		return <></>;
	}

	return (
		<>
			<Grid container justifyContent="center" alignItems="center" className="">
				<Grid item xs={9}>
					<h2 className="mt-2 text-app">Repeating {FamilyFeatureValue(user, Features.TransactionTitle)}s</h2>
				</Grid>
				<Grid item xs={2}>
					{!transactions && (< p ><em>Loading...</em></p>)}
					{transactions && (
						<>
							<IconButton
								className="text-app"
								aria-label='new expense'
								onClick={OpenNewModal}>
								<PlusLg />
							</IconButton>
						</>
					)}
				</Grid>
				<Grid item xs={3} className="centered">
					Display: 
				</Grid>
				<Grid item xs={3}>
					<Dropdown Size={12}
						Variant="standard"
						Class="mb-0"
						Options={options}
						Index={0}
						Value={selected}
						OnChange={UpdateSelected} />

				</Grid>
				<Grid item xs={6} className="centered">
					<Button variant="contained" onClick={ReviewUpcoming}><h3 className="my-1">View Upcoming</h3></Button>
				</Grid>
				{transactions != null && (
					<>
						{(transactions.length > 0) && (

							<Grid container>
								<>
									{
										transactions.map(transaction => {
											return (
												<div className="w-100" key={`transactdiv${transaction.recurringTransactionId}`}>
													<Recurring
														Item={transaction}
														OnClick={() => OpenModal(transaction.recurringTransactionId)}
														key={`transaction${transaction.recurringTransactionId}`} />
												</div>
											);
										})
									}
								</>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	async function SaveTransaction(item: RecurringItem) {
		HideModal();
		await repo.UpdateRecurringTransactionAsync(item, item.recurringTransactionId === 0, false);
		repo.GetBudgetsAsync();
		repo.GetCategoriesAsync();
		repo.GetRecurringTransactionsAsync();
		repo.GetTransactionsAsync();
	}

	function DeleteTransaction(item: RecurringItem) {
		repo.UpdateRecurringTransactionAsync(item, false, true);
		HideModal();
	}

	function OpenNewModal() {
		let selectedBudget = budgetTree?.find(b => b.isMine);
		if (!selectedBudget) {
			selectedBudget = budgetTree?.find(b => b.budgetId === 0);
		}
        let transaction = {
			recurringTransactionId: 0,
			accountId: accounts[0].accountId,
			budgetId: 0,
			categoryId: -1,
			days: 0,
			months: 1,
			description: '',
			nextAmount: 0,
			isVariable: false,
			minAmount: 0,
			maxAmount: 0,
			nextDate: new DateTime(),
			isIncome: selected === 2
		};
		modal = {
			...modal,
			Body: <EditRecurring Accounts={accounts} Transaction={transaction} Budgets={budgetTree} OnSave={SaveTransaction} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenModal(idx: number) {
		let transaction = transactions!.find(t => t.recurringTransactionId === idx)!;
		if (transaction) {
			modal = {
				...modal,
				Body: <EditRecurring Accounts={accounts} Transaction={transaction} Budgets={budgetTree} OnSave={SaveTransaction} OnDelete={DeleteTransaction} Close={HideModal} />,
                IsOpen: true,
                WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function ReviewUpcoming() {
		modal = {
			...modal,
			Body: <UpcomingReview Accounts={accounts} Transactions={transactions} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function UpdateSelected(value: number) {
		setSelected(value);
	}
}

export default Recurrings;