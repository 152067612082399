import { useDispatch, useSelector } from "react-redux";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";

const Modal = () => {
    let dispatch = useDispatch();
	let props: ModalProps = useSelector((state: any) => state.modal.value);
    let isMobile: ModalProps = useSelector((state: any) => state.isMobile.value);

    if (props.IsOpen && !props.WasOpen) {
        dispatch(setProps({ ...props, WasOpen: true }))
	}

    if (props.IsOpen && props.WasOpen) {
		return (
            <div className={isMobile ? "modals" : "desktop-modals"}>
                <div className={`wiz edit ${(props.IsOpen && props.WasOpen ? "active" : "hidden")}`}>
					<div className="edit-page" role="document">
						<div className="modal-content">
							{props.Body}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}

export default Modal;