import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import accountReducer from './features/Slices/accountSlice';
import budgetReducer from './features/Slices/budgetSlice';
import categoryReducer from './features/Slices/categorySlice';
import checkRequestReducer from './features/Slices/checkRequestSlice';
import isMobileReducer from './features/Slices/isMobileSlice';
import localDataReducer from './features/Slices/localDataSlice';
import modalReducer from './features/Slices/modalSlice';
import receiptEmailReducer from './features/Slices/receiptEmailSlice';
import recurringReducer from './features/Slices/recurringSlice';
import timeframeReducer from './features/Slices/timeframeSlice';
import transactionReducer from './features/Slices/transactionSlice';
import userDataReducer from './features/Slices/userDataSlice';
import userReducer from './features/Slices/userSlice';

export default configureStore({
    reducer: {
        accounts: accountReducer,
        budgets: budgetReducer,
		categories: categoryReducer,
		checkRequests: checkRequestReducer,
		isMobile: isMobileReducer,
		localData: localDataReducer,
		modal: modalReducer,
		recurring: recurringReducer,
        timeframes: timeframeReducer,
        receiptEmails: receiptEmailReducer,
        transactions: transactionReducer,
		userData: userDataReducer,
		users: userReducer,
	},
	middleware: getDefaultMiddleware({
		serializableCheck: false
	})
});