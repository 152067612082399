import { Dialog, DialogContent } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { CurrentUser, DateTime, FamilyFeatureValue, TransactionItem } from "../../types";
import Divider from "../Inputs/Divider";
import Transaction from "../ListItems/Transaction";

const ImportManualEntry = (props: any) => {
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	let fullyImportable = props.FullItems as TransactionItem[];
	let curDate: DateTime = new DateTime();

	return (
		<Dialog open={props.Open} onClose={props.Close}>
			<DialogContent className="px-5 mt-3" style={{ width: 600, maxWidth: "100%" }}>
				<h3 className="mb-3 text-app centered">We found the following importable {FamilyFeatureValue(user, Features.TransactionTitle)}s</h3>
				{
					fullyImportable.length > 0 && <>
						{
							fullyImportable.map(transaction => {
								return (
									<div className="w-100" key={`transactdiv${transaction.accountTransactionId}`}>
										{!IsCurrentDate(transaction) && (
											<Divider
												Text={curDate.toString()}
												key={`date${transaction.accountTransactionId}`} />
										)}
										<Transaction
											Item={transaction}
											Color={transaction.color}
											Categories={props.Categories}
											OnClick={() => props.ConfirmFull(transaction)}
											key={`transaction${transaction.accountTransactionId}`} />
									</div>
								);
							})
						}
					</>
				}
			</DialogContent>
		</Dialog>
	);

	function IsCurrentDate(transaction: TransactionItem) {
		if (curDate.Value.getTime() !== dayjs(transaction.transactionDate.Value).startOf('day').toDate().getTime()) {
			curDate.Value = dayjs(transaction.transactionDate.Value).startOf('day').toDate();
			return false;
		}
		return true;
	}
}

export default ImportManualEntry;