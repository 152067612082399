import { Grid } from "@mui/material";
import React from "react";
import { StarFill } from "react-bootstrap-icons";
import { AccountTypeDict } from "../../constants";
import { AccountItem, USCurrency } from "../../types";

const Account = (props: any) => {
    let item: AccountItem = props.Item;
    return (
		<Grid container className="btn-list text-app" onClick={props.OnClick}>
			<Grid item xs={1} className="centered text-warning" >
				{item.isDefault && (
					<StarFill />
				)}
			</Grid>
			<Grid item xs={6} >
				<h3 className="mb-0">{item.description}</h3>
			</Grid>
			<Grid item xs={5} className="right">
				{AccountTypeDict[item.accountTypeId]}
			</Grid>
			<Grid item xs={1} >
			</Grid>
			<Grid item xs={6} className="mb-3">
				x{item.mask}
			</Grid>
			<Grid item xs={5} className="right">
				{USCurrency(item.amount)}
			</Grid>
		</Grid >
    );
}

export default Account;