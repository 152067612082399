import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalData } from "../../types";

interface LocalDataState {
	value: LocalData | undefined
}

const initialState: LocalDataState = {
	value: undefined as LocalData | undefined
};

export const localDataSlice = createSlice({
    name: 'localData',
    initialState,
	reducers: {
		setLocalData: (state = initialState, action: PayloadAction<LocalData>) => {
            state.value = action.payload;
        },
        removeLocalData: (state = initialState) => {
			state.value = {} as LocalData;
		},
		updateLocalData: (state = initialState, action: PayloadAction<LocalData>) => {
            state.value = {...action.payload};
        }
    }
});

export const { setLocalData, removeLocalData, updateLocalData } = localDataSlice.actions;

export default localDataSlice.reducer;